import React, { useEffect, useState } from 'react'
import Footer from '../../components/footer'
import { connect } from 'react-redux';
import axios from 'axios';


import { ToastContainer, toast } from 'react-toastify';

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import { getSubsData, setBoardId, setStandardId } from '../../services/actions';

import { Link, useNavigate } from 'react-router-dom';
import { getDLectureData } from '../../utils';
import { Helmet } from 'react-helmet';



function Homepage(props) {
    const {user, boards} = props

    const navigate = useNavigate()

    const [sData, setSData] = useState([])
    const [selectedBId, setSelectedBId] = useState('')
    const [selectedSId, setSelectedSId] = useState('')
    const [subscriptionId, setSubscriptionId] = useState('')

    const [bannerData, setBannerData] = useState({type: "", data: []})
    const [testimonialData, setTestimonialData] = useState({type: "", data: []})
    const [demoLectureData, setDemoLectureData] = useState({type: "", data: []})

    const [availableAddOns, setAvailableAddOns] = useState([])

    const [currentActiveCardId, setCurrentActiveCardId] = useState("")

    const [subsData, setSubsData] = useState([])

    useEffect(() => {
        if(boards.selectedBoardId != ""){
            selectBoardHandler(boards.selectedBoardId)
        }
        if(boards.selectedStandardId){
            selectStandardHandler(boards.selectedStandardId)
        }
    }, [boards.selectedBoardId, boards.selectedStandardId])


    const selectBoardHandler = async (id) => {
        setSelectedBId(id)
        setSubsData([])
        props.setBoardId(id)
        props.setStandardId("")
        setSubscriptionId('')
        setSelectedSId('')
        setSData(boards?.data?.filter((item) => item.id == id)[0]?.standards)
    }

    const getWebPageBanners = async () => {
        try{
            await setBannerData({type: "request", data: []})
            let banners = await axios.get('/api/v3/s/get-webapp-banners/')
            
            if(banners.data.status == "error"){
                // toast.error(banners.data.response.statusDescription)
            }else{
                await setBannerData({type: "success", data: banners.data.response.data.banner})
            }
        }catch(e){
            console.log("error")
        }
    }

    const getClientTestimonials = async () => {
        try{
            setTestimonialData({...testimonialData, type: "request"})
            let testimonials = await axios.get('/api/v3/s/get-testomonials/')

            if(testimonials.data.status == "error"){
                // toast.error(testimonials.data.response.statusDescription)
            }else{
                setTestimonialData({type: "success",data:testimonials.data.response.data.testimonial})
            }
        }catch(e){
            console.log("error")
        }
    }

    useEffect(() => {
        getWebPageBanners()
        getClientTestimonials()
    }, [])
    useEffect(() => {
        if(user.login){
            try{
                getAddOnsData()
            }catch(e){
                console.log("error")
            }
        }
    }, [user])

    const getAddOnsData = async () => {
        try{
            let getAddOns = await axios.get('/api/v3/s/get-addons/')
        
            if(getAddOns.data.status === 'error'){
            // toast.error(getAddOns.data.response.statusDescription)
            }else{
            setAvailableAddOns(getAddOns.data.response.data.plan)
            }
        }catch(e){
            console.log("error")
        }
    }

    const getDemoLectureDetails = () => {
        // /api/v3/s/get-demo-lecture/
        setDemoLectureData({...demoLectureData,type: "request"})
        getDLectureData((res) => {
            if(res.type == "error"){
                // toast.error(res.message)
                setDemoLectureData({...demoLectureData,type: "error"})
            }else{
                setDemoLectureData({type: "success", data: res.data})
            }
        })
    }

    useEffect(() => {
        getDemoLectureDetails()
    }, [])

    const selectStandardHandler = (id) => {
        setSelectedSId(id);
        props.setStandardId(id)

        props.getSubsData(id , (res) => {
            if(res.type == 'success'){
              let sortedData = res.data.sort(function(a, b) {
                var keyA = a.price,
                  keyB = b.price;
                // Compare the 2 dates
                if (keyA < keyB) return -1;
                if (keyA > keyB) return 1;
                return 0;
              });
              
      
              setSubsData(sortedData)
            }else{
              // toast.error(res.message)
            }
          })
    }

    return (
        <>  
        <Helmet>‍
        <title>Eduaid Learning App</title>‍
        <meta name="description" content="Best Online Coaching for Class IX to XII" /> 
        <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Pets - Products" />        
        <meta name="twitter:description" content="Best Products for your pet" />        
        <meta name="twitter:image" content="url_to_image"/>        

      </Helmet>
            <ToastContainer />
            <div className='container-fluid' style={{maxWidth: "2000px"}}>
                <div id="carouselExampleCaptions" className="carousel slide carousel-box" data-bs-ride="carousel">
                    <div className="carousel-inner">

                            {bannerData.type == "request" ?
                                <h3 className='text-center text-white my-5'>Loading...</h3>
                                :
                                <OwlCarousel className='owl-theme' autoplay loop margin={10} nav items={1}>
                                    {bannerData.data.map((b_data, index) => {
                                        return(
                                            <div className='item' key={index} style={{display: "flex", alignItems: window.innerWidth <= 600 ? "flex-end" : "center",minHeight: "70vh",background: `url(${window.innerWidth <= 600 ? b_data.image_mobile : b_data.image})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat", cursor: "pointer"}} onClick={() => {
                                                window.open(b_data.redirection_link)
                                            }}>
                                                    <div className="container">
                                                        <div className="row align-items-center">
                                                            <div className="col-sm-8 col-7">
                                                                <div className="carousal-text-box">
                                                                    {window.innerWidth > 600 &&
                                                                        <div>
                                                                            <h5>
                                                                                {b_data.title}
                                                                            </h5>
                                                                            <p>
                                                                                {b_data.description}
                                                                            </p>
                                                                        </div>
                                                                    }
                                                                    {/* <a target='_blank' href={b_data.redirection_link ? b_data.redirection_link : "#"} className="btn btn-danger btn-dwnld">Download App</a> */}
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-4 col-5">
                                                                <div className="man-img">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            </div>
                                        )
                                    }) }
                                </OwlCarousel>
                            }
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                    </button>
                </div>
            </div>
            <section className="demo-mr">
                <div className="demo-class">
                    <h2>Watch a demo class</h2>
                </div>
                <div className="container cont-border-radius-demo">
                    <div className="row">
                        {demoLectureData.type == "request" ? 
                            <h3 className='text-center'>Loading...</h3>
                            :
                            demoLectureData.type == "success" &&
                                demoLectureData.data.map((item, index) => {
                                    return(
                                        <div className="col-md-6 col-sm-6 bordr-rt" key={index}>
                                            <div className="card custom-card">
                                                <div className="pr-video">
                                                    <video className="video-box ifram" src={item.video_url}
                                                        poster={item.lecture_image} controls>
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </div>
                                                <div className="card-body c-body">
                                                    <h6 className="card-title">
                                                    {item.title}
                                                    </h6>
                                                    <p className="card-text">
                                                    {item.description}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </div>
            </section>
            {user.login &&
                <section className="demo-mr">
                    <div className="demo-class">
                        <h2>What we offer</h2>
                    </div>
                    <div className="container main-seba cont-border-radius">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="explore">
                                    <h2>Explore our courses</h2>
                                </div>
                                <div className="course-flex">
                                    {boards.type == 'success'  ?
                                        boards.data.map((board, index) => {
                                            return (
                                                <span key={index} onClick={() => selectBoardHandler(board.id)} className={`show-cls ${board.id == selectedBId ? "active" : ""}`}>{board.name}</span>
                                            )
                                        })
                                        :
                                        <h4 style={{textAlign: "center"}}>Loading...</h4>
                                    }

                                </div>
                                <div className="class-flex">
                                    {selectedBId != ''?
                                        sData&&
                                         sData.map((standard, index) => {
                                            return (
                                                <span key={index} onClick={() => selectStandardHandler(standard.id)} className={`show-elite ${selectedSId == standard.id ? "active" : ""}`}>{standard.name_slug}</span>
                                            )
                                         })
                                         :
                                         <h4 style={{textAlign: "center"}}>Please select a board first</h4>
                                    }
                                </div>
                                <div className="class-flex">
                                    {selectedSId != ''?
                                         subsData.map((subs, index) => {
                                            return (
                                                <span key={index} onClick={() => {
                                                    setSubscriptionId(subs.id)
                                                }} className={`show-elite ${subscriptionId == subs.id ? "active" : ""}`}>{subs.name}</span>
                                            )
                                         })
                                         :
                                         <></>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='d-flex justify-content-center' style={{position: "relative"}}>
                            {subscriptionId != "" &&
                                subsData.map((data,index) => {
                                    return(

                                        <div className={`card-sldr align ${subscriptionId == data.id ? "d-block" : "d-none"} card-bg-img${index == 0 ? "" : index >= 2 ? "2" :index}`} style={{position: subscriptionId == data.id ? "relative" : "absolute" , zIndex: subscriptionId == data.id ? "10" : "-1", marginLeft: subscriptionId == data.id ?"0" :"-6rem"}}>
                                            <div className="row">
                                                <div className="col-md-6 col-6">
                                                    <div className="crown-box">
                                                        <img src="/assets/images/crown-blue.png" alt="" />
                                                        <span className="superclass">{data.name}</span>
                                                    </div>
                                                    <div className="grand-box">
                                                        <p>
                                                            {data.description}
                                                        </p>
                                                        <Link to="/subscription" style={{cursor: "pointer"}}>Read More...</Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-6">
                                                    <div className="recommend">
                                                        <h5>RECOMMENDED</h5>

                                                        <div className="rs-buy">
                                                            <p>₹ {data.price}/-</p>
                                                            <button className="btn btn-danger buy-now-btn" onClick={() => navigate('/buyPlan', {state: {data: {pageData: data, addOns:availableAddOns}}})} href="">Buy Now</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="container mt-2">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="my-4">
                                        <div className="cascade-slider_container" id="cascade-slider">
                                            <div className="cascade-slider_slides">
                                                <div className="cascade-slider_item ">
                                                    
                                                </div>
                                                <div className="cascade-slider_item">
                                                    
                                                </div>
                                                <div className="cascade-slider_item ">
                                                    
                                                </div>
                                                <div className="cascade-slider_item ">
                                                    
                                                </div>

                                            </div>

                                            <span className="cascade-slider_arrow cascade-slider_arrow-left" data-action="prev"><img
                                                src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/chvron-left.svg"
                                                type="image/svg+xml" /></span>
                                            <span className="cascade-slider_arrow cascade-slider_arrow-right" data-action="next"><img
                                                src="https://agenciafagulha.com.br/wp-content/uploads/2019/12/chvron-right.svg"
                                                type="image/svg+xml" /></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            }

            <section className="demo-mr">
                <div className="demo-class">
                    <h2>Why learning with EduAid is different</h2>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-8 connect-line-row-odd">
                            <div className="row cont-shadow1 align-items-center">
                                <div className="col-md-4 col-sm-4">
                                    <div>
                                        <img src="/assets/images/af1.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-8">
                                    <div className="learn-fast">
                                        <h6>Learn fast or slow</h6>
                                        <p>Students can learn at their own pace</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 offset-md-4 mt-2 connect-line-row-even">
                            <div className="row cont-shadow-intermediate-box align-items-center">
                                <div className="col-md-8 col-sm-8 order-2 order-md-1">
                                    <div className="learn-fast">
                                        <h6>Learn the best, from the best</h6>
                                        <p>
                                            High quality study materials, taught by top class teachers
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 order-1 order-md-2">
                                    <div>
                                        <img src="/assets/images/af2.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mt-2 connect-line-row-odd">
                            <div className="row cont-shadow-intermediate-box align-items-center">
                                <div className="col-md-4 col-sm-4">
                                    <div>
                                        <img src="/assets/images/af3.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-8">
                                    <div className="learn-fast">
                                        <h6>Learn better with visuals</h6>
                                        <p>Tough concepts simplified with visualisation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 offset-md-4 mt-2 connect-line-row-even">
                            <div className="row cont-shadow-intermediate-box align-items-center">
                                <div className="col-md-8 col-sm-8 order-2 order-md-1">
                                    <div className="learn-fast">
                                        <h6>Learn with technology</h6>
                                        <p>
                                            The app’s A.I helps teachers, students and parents identify
                                            gaps in learning
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 order-1 order-md-2">
                                    <div>
                                        <img src="/assets/images/af4.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8 mt-2">
                            <div className="row cont-shadow5 align-items-center">
                                <div className="col-md-4 col-sm-4">
                                    <div>
                                        <img src="/assets/images/af5.png" className="img-fluid" alt="" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-8">
                                    <div className="learn-fast">
                                        <h6>Learn with care</h6>
                                        <p>
                                            A team of caring teachers who can teach in English,
                                            Assamese, Bengali and Bodo.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="demo-mr">
                <div className="demo-class">
                    <h2>Our pride, our students</h2>
                </div>
                <div className="container cont-border-radius">
                    <div className="row">
                        <div className="col-md-12">
                            <h4 className="testi">Testimonials</h4>
                        </div>
                    </div>
                        <div className="row items">
                            <div className="col-md-12">
                            {testimonialData.type == "request" ?
                                <h3 className='text-center text-white my-5'>Loading...</h3>
                                :
                                testimonialData.type == "success" &&
                                <OwlCarousel className='owl-theme' autoplay loop margin={10} nav items={1}>
                                    {testimonialData.type == "success" ?
                                        testimonialData.data.map((t_data, index) => {
                                            return(
                                                <div className='item' key={index}>
                                                <div className="row">
                                                    <div className="col-md-3 col-sm-3 dis-fl">
                                                        <div className="test-box-img">
                                                            <img src={t_data.image} className="img-fluid" alt={t_data.name} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9 col-sm-9">
                                                        <div className="desc-box">
                                                            <p className="text-start left-i">
                                                                <i className="fa fa-quote-left fa-flip-vertical" aria-hidden="true"></i>
                                                            </p>
                                                            <p className="testi-text">
                                                                {t_data.feedback}
                                                            </p>
                                                            <p className="text-end right-i">
                                                                <i className="fa fa-quote-right fa-flip-vertical" aria-hidden="true"></i>
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <h4 className="kera">{t_data.name}</h4>
                                                    </div>
                                                </div>
                                                </div>
                                            )
                                        })
                                        :
                                        <h3 className='text-center text-white my-5'>Loading...</h3>
                                    }
                                </OwlCarousel>
                            }
                            </div>
                        </div>
                </div>
            </section>

            <section className="demo-mr">
                <div className="demo-class">
                    <h2>Why EduAid’s app is the best</h2>
                </div>
                <div className="container cont-border-radius">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card app-card">
                                <div className="img-div">
                                    <img src="/assets/images/ap1.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-pdg">
                                    <h5 className="card-title">Live Lessons</h5>
                                    <p className="card-text">
                                        Daily live sessions and one-on-one doubt discussions
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card app-card">
                                <div className="img-div">
                                    <img src="/assets/images/ap2.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-pdg">
                                    <h5 className="card-title">Progress Mapping</h5>
                                    <p className="card-text">
                                        A.I keeps track of student’s strengths and weaknesses
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card app-card">
                                <div className="img-div">
                                    <img src="/assets/images/ap3.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-pdg">
                                    <h5 className="card-title">Exam Excellence</h5>
                                    <p className="card-text">
                                        Customised revision modules for better preparation
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card app-card">
                                <div className="img-div">
                                    <img src="/assets/images/ap4.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-pdg">
                                    <h5 className="card-title">Gamified learning</h5>
                                    <p className="card-text">
                                        Compete with friends in real-time quizzes, with scorecards
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card app-card">
                                <div className="img-div">
                                    <img src="/assets/images/ap5.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-pdg">
                                    <h5 className="card-title">Free ebooks</h5>
                                    <p className="card-text">
                                        Access free ebooks from your course curriculum, with solved
                                        question papers
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card app-card">
                                <div className="img-div">
                                    <img src="/assets/images/ap6.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-pdg">
                                    <h5 className="card-title">Parental Supervision</h5>
                                    <p className="card-text">
                                        Separate app for parents to monitor their child’s progress
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="demo-mr">
                <div className="demo-class">
                    <h2>EduAid in news</h2>
                </div>
                <div className="container cont-border-radius-news">
                    <div className="row mr-news">
                        <div className="col-md-6">
                            <div className="card card-ns">
                                <div className="img-div">
                                    <img src="/assets/images/newsmain.png" className="card-img-top" alt="..." />
                                </div>
                                <div className="card-body card-news">
                                    <h5 className="card-title">
                                    Lockdown: EduAid, SEBA to provide online lessons to Class 10 Students.
                                    </h5>
                                    <p className="card-text">
                                    SEBA and SIQES EduAid LLP have come together to secure the future of students by provisioning them lessons via YouTube.
                                    </p>
                                    <a style={{wordBreak: "break-all"}} href="https://www.guwahatiplus.com/guwahati/lockdown-eduaid-seba-to-provide-online-lessons-for-class-10-students" target='_blank'>https://www.guwahatiplus.com/guwahati/lockdown-eduaid-seba-to-provide-online-lessons-for-class-10-students</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="row mt-3">
                                <div className="col-md-4 col-4">
                                    <div className="nws-img img-div">
                                        <img src="/assets/images/side1.webp" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-8">
                                    <div className="date-box">
                                        <h6>Assam: SEBA to take call on matric result announcement after lockdown</h6>
                                        <p>
                                        Subjects like science, mathematics, Assamese , Bodo and English are being taught online through EduAid in YouTube.
                                        </p>
                                        <a style={{wordBreak: "break-all"}} href="https://nenow.in/north-east-news/assam/assam-seba-to-take-call-on-matric-results-announcement-after-lockdown.html" target='_blank'>https://nenow.in/north-east-news/assam/assam-seba-to-take-call-on-matric-results-announcement-after-lockdown.html</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4 col-4">
                                    <div className="nws-img img-div">
                                        <img src="/assets/images/side1.webp" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-8">
                                    <div className="date-box">
                                        <h6>SEBA and the EduAid late signed an agreement to help and benefit the students</h6>
                                        <p>
                                        In a laudable effort, the SEBA, collaborating with SIQES EduAid LLP, an educational organisation, is opening online classes for all the Class X students under the Board due to lockdown.
                                        </p>
                                        <a style={{wordBreak: "break-all"}} href="https://www.sentinelassam.com/north-east-india-news/assam-news/lockdown-seba-opening-online-classes-for-class-10-students/" target='_blank'>https://www.sentinelassam.com/north-east-india-news/assam-news/lockdown-seba-opening-online-classes-for-class-10-students/</a>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-4 col-4">
                                    <div className="nws-img img-div">
                                        <img src="/assets/images/side1.webp" alt="" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="col-md-8 col-8">
                                    <div className="date-box">
                                        <h6>SEBA arranged for online classes through EduAid in Youtube for Class X students since March 25 last</h6>
                                        <p>
                                        The board is planning to conduct unit tests online uploading thequestion papers on Youtube.
                                        </p>
                                        <a style={{wordBreak: "break-all"}} href="https://www.pratidintime.com/seba-goes-online-final-call-on-result-declaration-after-lockdown/" target='_blank'>https://www.pratidintime.com/seba-goes-online-final-call-on-result-declaration-after-lockdown/</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="Coverage">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 bg-img-footover">
                            <div className="row">
                                <div className="col-md-6 col-6">
                                    <div className="spcl">
                                        <h3>Want Us to Email you About Special Offers & Updates?</h3>
                                    </div>
                                </div>
                                {!user.login ?
                                <div className="col-md-6 col-6 register-box">
                                    <div className="registr">
                                        <button className="regtr-btn" onClick={() => {
                                            document.getElementById("login-btn").click()
                                        }}>Register Now
                                            <img src="/assets/images/Arrow 2.png" className="img-fluid" alt="" /></button>
                                    </div>
                                </div>
                                :
                                <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user,
    boards: state.boards
  })
  
  const mapDispatchToProps = {
    getSubsData,
    setBoardId,
    setStandardId
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Homepage)