import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-sm-6 col-md-5">
                            <div>
                                <a className="logo-foot" href="https://eduaid.co.in">
                                    <img src="/assets/images/logofooter.png" />
                                    </a>                                
                                <div className="mbl-div">
                                    <ul className="mbl-link">
                                        <li>
                                            <a href="tel:(+91)(94779 94779)">
                                                <i className="text-white fa-solid fa-phone"></i>
                                                <span>+91 94779 94779 </span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="mailto:info@eduaid.co.in">
                                                <i className="text-white fa fa-envelope"></i>
                                                <span>info@eduaid.co.in</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <div className="">
                                <div className="foot-po">
                                    <ul>
                                        {/* <li>
                                            <a href="javascript:void(0)" className="about">
                                                Our leadership</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="privacy_policy">
                                                Our team</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="return_policy">Career</a>
                                        </li>
                                        <li>
                                            <a href="javascript:void(0)" className="termscondition">Internship</a>
                                        </li> */}
                                            <li><Link to="/about-us">About Us</Link></li>
                                            <li><Link to="/contact-us">Contact Us</Link></li>
                                            <li><Link to="/privacy-policy" target='_blank'>Privacy Policy</Link></li>
                                            <li><Link to="/terms-conditions" target='_blank'>Terms & Conditions</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-4">
                            <div className="">
                                <div className="foot-po">
                                    <h3>Follow Us</h3>

                                    <div className="Social-media mt-4">
                                        <a className="facbk" href="https://www.facebook.com/official.eduaid/" target='_blank'><i className="fa-brands fa-facebook-f"></i></a>
                                        <a href="https://www.instagram.com/eduaid_india/" target='_blank'><i className="fab fa-instagram"></i></a>
                                        <a href="https://www.youtube.com/@EduAid_India" target='_blank'><i className="fab fa-youtube"></i></a>
                                        <a href="https://twitter.com/EduAid_India" target='_blank'><i className="fab fa-twitter"></i></a>
                                    </div>

                                    <div className="android-mr mt-3">
                                        <a href="https://play.google.com/store/apps/details?id=com.eduaid.child" target='_blank'>
                                            <img src="/assets/images/andriod.png" className="img-fluid" alt="" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="footer-bottom-area">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 py-3">
                            <p className='text-center text-small'>
                                <i className="fa-regular fa-copyright"></i>2023 EduAid All Rights
                                Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Footer