import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { getAllChatLists } from '../../services/actions';

function EduhelperDashboard(props) {
    const {eduhelper} = props

    const loadAllChats = () => {
        props.getAllChatLists()
    }

    useEffect(() => {
        loadAllChats()
    }, [])

  return (
    <div className='mt-5'>
        {JSON.stringify(eduhelper.pendingChatList.data, null , 4)}
    </div>
  )
}

const mapStateToProps = (state) => ({
    eduhelper: state.eduhelper
});

const mapDispatchToProps = {
    getAllChatLists
};

export default connect(mapStateToProps, mapDispatchToProps)(EduhelperDashboard);