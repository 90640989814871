import React, { useState } from 'react'

function SetPasswordModal(props) {
    const {setPasswordHandler} = props
    const [pError, setPError] = useState('')

    const [showPassword, setShowPassword] = useState(false)
    const [showCPassword, setShowCPassword] = useState(false)

    const [password, setPassword] = useState('')
    const [cnfPassword, setCnfPassword] = useState('')
    

    return (
        <>
            <div className="modal fade" id="setpwdmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-ku">
                    <div className="modal-content">
                        <div className="modal-body m-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 pdg-0">
                                        <div className="otp-img">
                                            <img src="assets/images/setpwd.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-logo">
                                                    <div className="logo-otp">
                                                        <img src="assets/images/logoedu.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="codes2">Set Password</p>
                                                    <p className="mbl-no1">
                                                        Please set Login Password for your account.
                                                    </p>

                                                    <div className="pass--box">
                                                        <input type={showPassword ? "text" : "password"} placeholder="Password" id="password_two"
                                                            className="form-control password_box" value={password} onChange={(e) => setPassword(e.target.value)}/>
                                                        <i onClick={() => {
                                                            setShowPassword(!showPassword)
                                                        }} className={`password-eyes ${showPassword ? "fa-solid fa-eye" : "fa fa-eye-slash"}`} id="eye2"
                                                            area-hidden="true"></i>
                                                    </div>
                                                    <div className="pass--box mt-3">
                                                        <input  type={showCPassword ? "text" : "password"} placeholder="Confirm Password..."
                                                            id="password_three" className="form-control password_box" value={cnfPassword} onChange={(e) => setCnfPassword(e.target.value)}/>
                                                        <i onClick={() => {
                                                            setShowCPassword(!showCPassword)
                                                        }} className={`password-eyes ${showCPassword ? "fa-solid fa-eye" : "fa fa-eye-slash"}`} id="eye3"
                                                            area-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div className="mb-3 text-center">
                                                    <button className={`btn btn-danger btnotp1 ${pError != '' ? "btn-req" : ""}`} disabled={pError!= ''} onClick={() => setPasswordHandler(password, cnfPassword)}>
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="privacy2">
                                                    <p>
                                                        By continuing you consent to share your information
                                                        with EduAid & agree to our <span>T&C</span> and
                                                        <span>Privacy Policy</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SetPasswordModal