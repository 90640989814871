import React from 'react'

function AcceleratorHeader() {
  return (
    <nav class="navbar navbar-expand-lg navbar-light">
        <div className="container">
            <a class="navbar-brand" href="#"><img
                src="assets/images/eduaid-logo-main.png"
                className="img-fluid"
                alt=""
              /></a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                <li class="nav-item active">
                    <a class="nav-link" href="#hero-section"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Home</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#scholarships"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Scholarships</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#benefits"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Benefits</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#test"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Test</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#success-stories"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Success Stories</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#registration-process"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Registration Process</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#syllabus"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Syllabus</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#contact"><span data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent">Contact Us</span></a>
                </li>
                </ul>
            </div>
        </div>
    </nav>
  )
}

export default AcceleratorHeader