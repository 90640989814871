import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

function LoginModal(props) {
    const {user, apiCalled} = props
    const {loginUser} = props
    const [phoneN, setPhoneN] = useState('')

    const [loginError, setLoginError] = useState('')

    return (
        <>
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-ku">
                    <div className="modal-content">
                        <div className="modal-body m-pdg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="boy-login">
                                            <img src="assets/images/boylogin.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="text-center logo-edu">
                                                    <img src="assets/images/logoedu.png" className="img-fluid" alt="" />
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="form-mbl text-center">
                                                        Please enter your mobile number to Login/Register
                                                    </p>
                                                    <input className="form-control" value={phoneN} onChange={(e) => {
                                                        setPhoneN(e.target.value)
                                                        setLoginError('')
                                                    }} onKeyDown={(e) => {
                                                        if(e.code == "Enter"){
                                                            loginUser(phoneN)
                                                        }
                                                    }} id="phone" type="tel" />
                                                    {loginError != '' &&
                                                        <p className='error-msg'>{loginError}</p>
                                                    }
                                                </div>
                                                <div className="mb-3 text-center">
                                                    <button disabled={(loginError != '' || apiCalled || user.type == 'request')} className={`btn btn-danger btnotp ${(loginError != '' || apiCalled || user.type == 'request') ? "btn-req" : ""}`} onClick={() => {
                                                        loginUser(phoneN)
                                                    }}>
                                                        {user.type == 'request' ? "Continuing..." : "Continue"}
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="privacy">
                                                    <p>
                                                        By continuing you consent to share your information
                                                        with EduAid & agree to our <Link to="/terms-conditions" target='_blank'>T&C</Link> and 
                                                        <Link to="/privacy-policy" target='_blank'> Privacy Policy</Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
  })
  
  const mapDispatchToProps = {
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(LoginModal)