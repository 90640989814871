import React, { useState } from 'react'
import { connect } from 'react-redux'

function CallModal(props) {
    const {user, apiCalled} = props
    const [username, setUsername] = useState('')
    const [avatarId, setAvatarId] = useState('')
    const [selectedAvatar, setSelectedAvatar] = useState('')

    const {avatars, setUserInfo} = props

    return (
        <>
            <div className="modal fade" id="callingmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-ku">
                    <div className="modal-content">
                        <div className="modal-body m-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 pdg-0">
                                        <div className="otp-img">
                                            <img src="/assets/images/calling.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-logo">
                                                    <div className="logo-otp">
                                                        <img src="/assets/images/logoedu.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="school school-mar">
                                                    <h6>Would you like us to call you?</h6>
                                                    <div className="">
                                                        <input type="text" placeholder="Enter Your full name here..."
                                                            id="username" value={username} onChange={(e) => {
                                                                setUsername(e.target.value)
                                                            }} className="form-control password_box" />
                                                    </div>
                                                    <h6 className="mt-3">
                                                        +Add You own
                                                        <span className="pls">(Please add your own photo for better
                                                            identification)</span>
                                                    </h6>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <div>
                                                            <img src={selectedAvatar == "" ? "/assets/images/avtar.png" : selectedAvatar} width={100} className="img-fluid" alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="slick-slider">
                                                            <div style={{display: "flex", overflow: "auto", height: "120px", flexWrap: "wrap"}}>
                                                            {avatars.map((a_data, index) => {
                                                                return (
                                                                    <div key={index} className="element element-1" style={{width: "100px", height: "100px"}} onClick={() => {
                                                                        setAvatarId(a_data.id)
                                                                        setSelectedAvatar(a_data.image)
                                                                    }}>
                                                                        <img width="100" height="100" src={a_data.image} className="img-fluid" alt={a_data.name} />
                                                                    </div>
                                                                )
                                                            })}
                                                            </div>
                                                            {/* <div className="element element-2">
                                                                <img src="assets/images/a2.png" className="img-fluid" />
                                                            </div>
                                                            <div className="element element-3">
                                                                <img src="assets/images/a3.png" className="img-fluid" />
                                                            </div>
                                                            <div className="element element-4">
                                                                <img src="assets/images/a4.png" className="img-fluid" />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 text-center">

                                                    <button disabled={apiCalled || user.type == 'request'} className={`btn btn-danger rad-btn ${(apiCalled || user.type == 'request' ? "btn-req" : "")}`} onClick={() => {
                                                        setUserInfo(username)
                                                    }}>
                                                        {(user.type == 'request' || apiCalled) ? "Continuing..." : "Continue"}
                                                        
                                                    </button>
                                                    <button id={"close-btn"} style={{display: "none"}} className="btn btn-danger rad-btn" data-bs-dismiss="modal">
                                                        Closess
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="privacy5">
                                                    <p>
                                                        By continuing you consent to share your information
                                                        with EduAid & agree to our <span>T&C</span> and
                                                        <span>Privacy Policy</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user
})
  
const mapDispatchToProps = {

}
  
  export default connect(mapStateToProps, mapDispatchToProps)(CallModal)