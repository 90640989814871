import React from 'react'

function TestComponent() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center top-input mb-3">
                  <button><i className="fa-solid fa-magnifying-glass"></i></button
                  ><input type="text" placeholder="Search" />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="d-flex align-items-center f-famaly create-test">
                  <h3>Create Test</h3>
                  <img
                    src="./assets/images/image-20.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div
                  className="d-flex align-items-center f-famaly create-test bg-two"
                >
                  <h3>
                    Scheduled <br />
                    Test
                  </h3>
                  <img
                    src="./assets/images/purple-calendar -23.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div
                  className="d-flex align-items-center f-famaly create-test bg-three"
                >
                  <h3>Live Test</h3>
                  <img
                    src="./assets/images/3d-22.png"
                    className="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="create-box">
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="ct-test">Create Test</h4>
                  </div>
                  <div className="col-md-6">
                    <h5 className="testnm">Test Name</h5>
                    <div className="create-input">
                      <input type="text" placeholder="Create test name" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <h4 className="testnm mt-3 mt-sm-1">Select Subject</h4>
                    <div className="select">
                      <select>
                        <option value="0">demo</option>
                        <option value="1">demo-1</option>
                        <option value="2">demo-2</option>
                        <option value="3">demo-3</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-md-12 mt-4">
                    <div>
                      <h4 className="s-subject">Select Chapters</h4>
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <div className="chapters-subj">
                      <div className="row just-cont">
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 1
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 2
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 3
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row just-cont">
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 4
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 5
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 6
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row just-contlast">
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 7
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 8
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                          <div className="form-check align-items-center">
                            <label
                              className="form-check-label"
                              htmlFor="exampleRadios1"
                            >
                              Chapter 9
                            </label>
                            <input
                              className="form-check-input float-end select-subj-input"
                              type="radio"
                              name="exampleRadios"
                              id="exampleRadios1"
                              value="option1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="uestion">
                      <img
                        src="./assets/images/question-1.png"
                        className="img-fluid"
                        alt=""
                      />
                      <span>uestion per chapter</span>
                    </div>
                    <div className="sliderbox">
                      <table className="slider2column">
                        <tr>
                          <td>
                            <div className="slidershell" id="slidershell1">
                              <div className="sliderfill" id="sliderfill1"></div>
                              <div className="slidertrack" id="slidertrack1"></div>
                              <div className="sliderthumb" id="sliderthumb1"></div>
                              <div className="slidervalue" id="slidervalue1">2</div>
                              <input
                                className="slider"
                                id="slider1"
                                type="range"
                                min="2"
                                max="48"
                                value="0"
                                oninput="showValue(value,1,false);"
                                onchange="showValue(value,1,false);"
                              />
                            </div>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div
                    className="col-md-2 col-sm-6 col-12 justify-content-md-center d-md-flex align-items-md-center text-center"
                  >
                    <div className="total-qus">
                      <span>Total question</span>
                      <div>18</div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 col-12">
                    <div className="uestion">
                      <img
                        src="./assets/images/question-2.png"
                        className="img-fluid"
                        alt=""
                      />
                      <span className="timeper">Time per question</span>
                    </div>
                    <div className="mx-2 my-2">
                      <div className="sliderbox">
                        <table className="slider2column">
                          <tr>
                            <td>
                              <div className="slidershell" id="slidershell2">
                                <div className="sliderfill" id="sliderfill2"></div>
                                <div
                                  className="slidertrack"
                                  id="slidertrack2"
                                ></div>
                                <div
                                  className="sliderthumb"
                                  id="sliderthumb2"
                                ></div>
                                <div className="slidervalue" id="slidervalue2">
                                  2
                                </div>
                                <input
                                  className="slider"
                                  id="slider2"
                                  type="range"
                                  min="2"
                                  max="48"
                                  value="0"
                                  oninput="showValue(value,2,false);"
                                  onchange="showValue(value,2,false);"
                                />
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-2 col-sm-6 col-12 justify-content-md-center d-md-flex align-items-md-center text-center"
                  >
                    <div className="total-qus">
                      <span>Total time</span>
                      <div>18 mint</div>
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <div className="text-center sub-button">
                      <button>Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default TestComponent