import React from 'react';
import RoutesObj from './routes';

// All Css imports
import './styles/app.css';
import './styles/style.css'
import './styles/responsive.css'
import './styles/subscription.css'
import './styles/checkout.css'
import './styles/dropdown.css'
import './styles/eduhelper.css'
import './styles/accelerator.css'
import 'react-toastify/dist/ReactToastify.css';



import axios from 'axios';

import configureStore from "./services/configureStore";
import { Provider } from 'react-redux';



axios.interceptors.response.use(undefined,(err) => {
  if (err.response && [400,401,403,404,500].includes(err.response.status)){
    return err.response
  }
})


const store = configureStore();

function App() {
  return (
    <React.Fragment> 
      <Provider store={store}>  
        <RoutesObj/>
      </Provider>   
    </React.Fragment>
  );
}

export default App;
