import React from 'react'

function TermsConditions() {
  return (
    <div className="container py-5">
      <td>
        {" "}
        <p>
          <strong>Terms and Conditions</strong>
        </p>
        <p>Last updated: August 08, 2021</p>
        <p>
          These Terms and Conditions ("Terms", "Terms and Conditions") govern
          your relationship with&nbsp;
          <a href="http://eduaid.co.in/">http://eduaid.co.in</a>&nbsp;website
          and eduaid mobile application (the "Service") operated by SIQES EDUAID
          LLP ("us", "we", or "our").
        </p>
        <p>
          Please read these Terms and Conditions carefully before using our
          website and eduaid mobile application (the "Service").
        </p>
        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>
        <p>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </p>
        <p>
          <strong>Purchases</strong>
        </p>
        <p>
          If you wish to purchase any product or service made available through
          the Service ("Purchase"), you may be asked to supply certain
          information relevant to your Purchase including, without limitation,
          your credit card number, the expiration date of your credit card, your
          billing address, and your shipping information.
        </p>
        <p>
          You represent and warrant that: (i) you have the legal right to use
          any credit card(s) or other payment method(s) in connection with any
          Purchase; and that (ii) the information you supply to us is true,
          correct and complete.
        </p>
        <p>
          By submitting such information, you grant us the right to provide the
          information to third parties for purposes of facilitating the
          completion of Purchases.
        </p>
        <p>
          We reserve the right to refuse or cancel your order at any time for
          certain reasons including but not limited to: product or service
          availability, errors in the description or price of the product or
          service, error in your order or other reasons.
        </p>
        <p>
          We reserve the right to refuse or cancel your order if fraud or an
          unauthorised or illegal transaction is suspected.
        </p>
        <p>
          <strong>Availability, Errors and Inaccuracies</strong>
        </p>
        <p>
          We are constantly updating our offerings of products and services on
          the Service. The products or services available on our Service may be
          mispriced, described inaccurately, or unavailable, and we may
          experience delays in updating information on the Service and in our
          advertising on other web sites.
        </p>
        <p>
          We cannot and do not guarantee the accuracy or completeness of any
          information, including prices, product images, specifications,
          availability, and services. We reserve the right to change or update
          information and to correct errors, inaccuracies, or omissions at any
          time without prior notice.
        </p>
        <p>
          <strong>Contests, Sweepstakes and Promotions</strong>
        </p>
        <p>
          Any contests, sweepstakes or other promotions (collectively,
          "Promotions") made available through the Service may be governed by
          rules that are separate from these Terms. If you participate in any
          Promotions, please review the applicable rules as well as our Privacy
          Policy. If the rules for a Promotion conflict with these Terms, the
          Promotion rules will apply.
        </p>
        <p>
          <strong>Subscriptions</strong>
        </p>
        <p>
          Some parts of the Service are billed on a subscription basis
          ("Subscription(s)"). You will be billed in advance on a recurring and
          periodic basis ("Billing Cycle"). Billing cycles are set either on a
          monthly or annual basis, depending on the type of subscription plan
          you select when purchasing a Subscription.
        </p>
        <p>
          At the end of each Billing Cycle, your Subscription will automatically
          renew under the exact same conditions unless you cancel it or SIQES
          EDUAID LLP cancels it. You may cancel your Subscription renewal either
          through your online account management page or by contacting SIQES
          EDUAID LLP customer support team.
        </p>
        <p>
          A valid payment method, including credit card or PayPal, is required
          to process the payment for your Subscription. You shall provide SIQES
          EDUAID LLP with accurate and complete billing information including
          full name, address, state, zip code, telephone number, and a valid
          payment method information. By submitting such payment information,
          you automatically authorize SIQES EDUAID LLP to charge all
          Subscription fees incurred through your account to any such payment
          instruments.
        </p>
        <p>
          Should automatic billing fail to occur for any reason, SIQES EDUAID
          LLP will issue an electronic invoice indicating that you must proceed
          manually, within a certain deadline date, with the full payment
          corresponding to the billing period as indicated on the invoice.
        </p>
        <p>
          <strong>
            EduAid Improvement Guarantee (EIG) with 100% moneyback.
          </strong>
        </p>
        <p>
          Our guarantee is applicable on our long-term courses with tenure more
          than 8 months.
        </p>
        <p>
          We at EduAid take complete responsibility of your child’s progress in
          our long-term courses. Therefore, we introduced the 100 % moneyback
          guarantee taking complete ownership and accountability of your child’s
          development.
        </p>
        <p>
          EduAid promises improvement on upcoming results to a certain extent,
          based on previous year results of a student. However, if the student
          is not able to secure a better result than the previous year, 100%
          money-back will be guaranteed only based upon the fulfilment of the
          conditions mentioned below.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Conditions:</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Applicable for students with 55%
          - 70% in previous year results.
        </p>
        <p>
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Min. 75% attendance in all
          EduAid Live sessions of all subjects (viz. Maths, Physics, Chemistry,
          Biology, English-Prose, English-Poetry, English-Grammar, Social
          Studies).
        </p>
        <p>
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Min. 75% completion&nbsp;of
          EduAid Recorded Videos of all subjects (viz. Maths, Physics,
          Chemistry, Biology, English-Prose, English-Poetry, English-Grammar).
        </p>
        <p>
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Regularity in Practice and Test
          module in EduAid Learning App.
        </p>
        <p>
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 100% attendance in Formative
          Tests Module.
        </p>
        <p>
          ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Regularity in DPP (Daily
          Practice Paper) sheets solving.
        </p>
        <p>&nbsp;</p>
        <p>
          If any certain condition is not fulfilled by the student, he/she will
          not be applicable for 100% money-back guarantee.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Fee Changes</strong>
        </p>
        <p>
          SIQES EDUAID LLP, in its sole discretion and at any time, may modify
          the Subscription fees for the Subscriptions. Any Subscription fee
          change will become effective at the end of the then-current Billing
          Cycle.
        </p>
        <p>
          SIQES EDUAID LLP will provide you with a reasonable prior notice of
          any change in Subscription fees to give you an opportunity to
          terminate your Subscription before such change becomes effective.
        </p>
        <p>
          Your continued use of the Service after the Subscription fee change
          comes into effect constitutes your agreement to pay the modified
          Subscription fee amount.
        </p>
        <p>
          <strong>Refund Policy for Student Subscription Plan</strong>
        </p>
        <p>
          We at SIQES EduAid are committed to providing the best services and experiences to our student subscribers. We understand that circumstances may arise where you need to cancel your subscription and request a refund. Please review our refund policy outlined below:
        </p>
        <p>
        <strong>Eligibility for Refund:</strong>
        </p> 
        <p>
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refunds are only applicable within 7 days of the initial subscription purchase.
          </p> 
        <p>
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To be eligible for a refund, you must submit your request within the specified refund period.
        </p>
        <p>
        ·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refunds will only be considered for the initial subscription fee, not for any additional purchases or transactions made within the subscription.
        </p>
        <p>
        <strong>Reasons for Refund:</strong>
        </p>
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;We accept refund requests for the following reasons:</p>
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Technical issues that prevent access to the subscribed services despite reasonable troubleshooting attempts.</p>
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dissatisfaction with the quality of the services provided, subject to verification and investigation by our customer support team.</p>
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any other legitimate reasons deemed acceptable by SIQES EduAid after review.</p>
          
        <p><strong>Refund Procedure:</strong></p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To request a refund, you must contact our customer support team via email or through our designated support portal.</p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your refund request must include your subscription details, reason for the refund, and any supporting documentation if applicable.</p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Once your request is received, our customer support team will review it within 3 business days and notify you of the decision.</p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If your refund request is approved, the refund will be processed back to the original payment method used for the subscription purchase.</p>
          
        <p><strong>Exceptions to Refund:</strong></p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;No refunds will be issued for subscription cancellations or refund requests made after the specified refund period has elapsed.</p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refunds will not be provided for subscription renewals, unless explicitly stated otherwise in a separate agreement or promotion.</p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Any promotional discounts or offers applied to the subscription fee will not be refunded.</p>
          
        <p><strong>Dispute Resolution:</strong></p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case of any disputes regarding refunds, both parties agree to engage in good faith negotiations to resolve the issue amicably.</p>
          
        <p>·&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;If a satisfactory resolution cannot be reached, either party may seek legal recourse as per the terms outlined in our Terms of Service.</p>
          
        <p><strong>Changes to Refund Policy:</strong></p>
          
        <p>SIQES EduAid reserves the right to modify or update this refund policy at any time without prior notice. Any changes will be effective immediately upon posting on our website.</p>
          
        <p>  By subscribing to our services, you acknowledge that you have read, understood, and agreed to abide by the terms of this refund policy. If you have any questions or concerns regarding our refund policy, please contact our customer support team for assistance.</p>      
        <p>
          <strong>Content</strong>
        </p>
        <p>
          Our Service allows you to post, link, store, share and otherwise make
          available certain information, text, graphics, videos, or other
          material ("Content"). You are responsible for the Content that you
          post to the Service, including its legality, reliability, and
          appropriateness.
        </p>
        <p>
          By posting Content to the Service, you grant us the right and license
          to use, modify, publicly perform, publicly display, reproduce, and
          distribute such Content on and through the Service. You retain any and
          all of your rights to any Content you submit, post or display on or
          through the Service and you are responsible for protecting those
          rights. You agree that this license includes the right for us to make
          your Content available to other users of the Service, who may also use
          your Content subject to these Terms.
        </p>
        <p>
          You represent and warrant that: (i) the Content is yours (you own it)
          or you have the right to use it and grant us the rights and license as
          provided in these Terms, and (ii) the posting of your Content on or
          through the Service does not violate the privacy rights, publicity
          rights, copyrights, contract rights or any other rights of any person.
        </p>
        <p>
          <strong>Accounts</strong>
        </p>
        <p>
          When you create an account with us, you must provide us information
          that is accurate, complete, and current at all times. Failure to do so
          constitutes a breach of the Terms, which may result in immediate
          termination of your account on our Service.
        </p>
        <p>
          You are responsible for safeguarding the password that you use to
          access the Service and for any activities or actions under your
          password, whether your password is with our Service or a third-party
          service.
        </p>
        <p>
          You agree not to disclose your password to any third party. You must
          notify us immediately upon becoming aware of any breach of security or
          unauthorized use of your account.
        </p>
        <p>
          You may not use as a username the name of another person or entity or
          that is not lawfully available for use, a name or trade mark that is
          subject to any rights of another person or entity other than you
          without appropriate authorization, or a name that is otherwise
          offensive, vulgar or obscene.
        </p>
        <p>
          <strong>Intellectual Property</strong>
        </p>
        <p>
          The Service and its original content (excluding Content provided by
          users), features and functionality are and will remain the exclusive
          property of SIQES EDUAID LLP and its licensors. The Service is
          protected by copyright, trademark, and other laws of both the India
          and foreign countries. Our trademarks and trade dress may not be used
          in connection with any product or service without the prior written
          consent of SIQES EDUAID LLP.
        </p>
        <p>
          <strong>Links To Other Web Sites</strong>
        </p>
        <p>
          Our Service may contain links to third-party web sites or services
          that are not owned or controlled by SIQES EDUAID LLP.
        </p>
        <p>
          SIQES EDUAID LLP has no control over, and assumes no responsibility
          for, the content, privacy policies, or practices of any third party
          web sites or services. You further acknowledge and agree that SIQES
          EDUAID LLP shall not be responsible or liable, directly or indirectly,
          for any damage or loss caused or alleged to be caused by or in
          connection with use of or reliance on any such content, goods or
          services available on or through any such web sites or services.
        </p>
        <p>
          We strongly advise you to read the terms and conditions and privacy
          policies of any third-party web sites or services that you visit.
        </p>
        <p>
          <strong>Termination</strong>
        </p>
        <p>
          We may terminate or suspend your account immediately, without prior
          notice or liability, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </p>
        <p>
          Upon termination, your right to use the Service will immediately
          cease. If you wish to terminate your account, you may simply
          discontinue using the Service or using this link &nbsp;
          <a href='https://eduaid.co.in/account-termination.html'>Account closure</a>.
        </p>
        <p>
          <strong>Limitation Of Liability</strong>
        </p>
        <p>
          In no event shall SIQES EDUAID LLP, nor its directors, employees,
          partners, agents, suppliers, or affiliates, be liable for any
          indirect, incidental, special, consequential or punitive damages,
          including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from (i) your access to or use of
          or inability to access or use the Service; (ii) any conduct or content
          of any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>
        <p>
          <strong>Disclaimer</strong>
        </p>
        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>
        <p>
          SIQES EDUAID LLP its subsidiaries, affiliates, and its licensors do
          not warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects
          will be corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </p>
        <p>
          <strong>Governing Law</strong>
        </p>
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of Assam, India, without regard to its conflict of law
          provisions.
        </p>
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have between us
          regarding the Service.
        </p>
        <p>
          <strong>Changes</strong>
        </p>
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will try to
          provide at least 30 days notice prior to any new terms taking effect.
          What constitutes a material change will be determined at our sole
          discretion.
        </p>
        <p>
          By continuing to access or use our Service after those revisions
          become effective, you agree to be bound by the revised terms. If you
          do not agree to the new terms, please stop using the Service.
        </p>
        <p>
          <strong>Contact Us</strong>
        </p>
        <p>If you have any questions about these Terms, please contact us.@ wecare@eduaid.co.in or call us at +919477994779 </p>
        <p>
          <strong>Our Corporate Address:</strong></p>
          <p>SIQES EDUAID LLP, DIVINE PLAZA, 1, G.S. ROAD, SUPERMARKET, Assam, 781006 (INDIA)
          </p>
                 
        <p>&nbsp;</p>
      </td>
    </div>
  );
}

export default TermsConditions