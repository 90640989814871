import React from 'react'

function AcceleratorBatch() {
    
  return (
    <section className='homepage'>
        <div className='hero-section' id='hero-section'>
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-lg-6">
                        <h6 className='success-assurance'>#YourPathtoAcademicExcellence</h6>
                        <h1 className='title'>
                        EduAid Excelerator Batch Selection Test – Your Gateway to Success!
                        </h1>

                        <h4 className='helper-text'>For Class 10 HSLC Candidates of CBSE and SEBA Boards.</h4>

                        <p className='para-text'>Unlock exclusive discounts of up to 100% on Excelerator Batch and even enjoy FREE admission for top-performing students. Join our program with a remarkable 95% success rate, providing you with the proven results you need.</p>

                        <button className='btn-dwnld text-white' onClick={() => {
                            window.open("https://rzp.io/l/excelerator-batch")
                        }}>Register Now <i class="fa-solid fa-arrow-right"></i></button>
                    </div>
                    <div className="col-lg-6">
                        <img src='/assets/accelerator/student_transparent.png' alt='student image' className='img-fluid'/>
                    </div>
                </div>
            </div>
        </div>

        <div className='scholarships' id='scholarships'>
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-6">
                                <div className="card prize-detail-card">
                                    <img src='/assets/accelerator/cash-prize.png' alt='cash-prize'/>
                                    <h6>Discounts For EduAid Excelerator Batch Upto</h6>
                                    <h1>100 %</h1>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card prize-detail-card">
                                    <img src='/assets/accelerator/scholarship.png' alt='scholarship'/>
                                    <h6>FREE Admission for</h6>
                                    <h1>Top</h1>
                                    <p>Performers</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card prize-detail-card">
                                    <img src='/assets/accelerator/trophy.png' alt='trophy'/>
                                    <h6>Proven results: </h6>
                                    <h1>95%</h1>
                                    <p>success rate.</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="card prize-detail-card">
                                    <img src='/assets/accelerator/award.png' alt='award'/>
                                    <h6>Get your exam</h6>
                                    <h1>readiness</h1>
                                    <p>Score</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 right_side">
                        <h1>Excelerator Batch Selection Test 2023</h1>

                        <h5>Exam scheduled</h5>
                        <p className='dates'><b>Online : </b> 18th Oct and 29th Oct 2023</p>

                        <p className='desc'>Don't miss out on our incredible Special Offer! Excelerator Batch is within your reach, and all it takes is acing our Selection Test. Score above 90% in the test, and you'll be eligible for a substantial course fee discount. But here's the real deal: stand out as a top performer, and you could secure FREE admission to the coveted Excelerator Batch. This is your golden opportunity to excel academically.</p>

                        <div className='row mt-4'>
                            <div className='col-3 icon-area first'>
                                <i class="fa-solid fa-book"></i>
                            </div>
                            <div className='col-9'>
                                <h2>Exam Conduction Procedure and details</h2>
                                <p>The Selection Test on the EduAid Learning App features a 120-minute MCQ-based pattern, covering English (Prose, Poetry, Grammar), Science, and Mathematics. The syllabus is your Class 10 (CBSE/SEBA) half-yearly course. Get ready to showcase your knowledge and skills!</p>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-3 icon-area second'>
                                <i class="fa-regular fa-calendar"></i>
                            </div>
                            <div className='col-9'>
                                <h2>Exam Results</h2>
                                <p>Result for online Selection Test will be declared within a few days after the test. Register now for the Admission Test and embark on a journey towards excellence!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="benefits py-5" id='benefits'>
            <div className="container">
                <div className="row d-flex align-items-center">
                    <div className="col-md-6">
                        <div className='d-flex flex-wrap'>
                            <img src='/assets/accelerator/benefits_1.jpg' style={{maxWidth: "300px"}} alt='benefits' className='img-fluid'/>
                            <img src='/assets/accelerator/benefits_2.jpg' style={{maxHeight: "300px"}} alt='benefits' className='img-fluid'/>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <img src='/assets/accelerator/benefits_3.jpeg' alt='benefits' className='img-fluid'/>
                        </div>
                    </div>

                    <div className="col-md-6 right_side">
                        <h6>Excelerator Batch Selection Test 2023 is the gateway for </h6>
                        <h1>Excelerator Batch 2023-24</h1>

                        <p>At Excelerator Batch, we are dedicated to empowering SEBA and CBSE board students in Assam to excel in their Class X board exams with comprehensive support and resources. With a proven track record of outstanding achievements,  EduAid Learning is committed to helping you achieve your academic dreams.</p>
                    </div>
                </div>

                <div className="row mt-5">
                    <div className="col-md-4">
                        <div className="row benefits-card">
                            <div className="col-3">
                                <div className='icon-area-benefits'>
                                    <i class="fa-solid fa-medal"></i>
                                </div>
                            </div>
                            <div className="col-9">
                                <h3>Interactive Learning Sessions</h3>
                                <p> At Excelerator Batch, we prioritize interactive learning. Our daily live sessions in Mathematics, Science, and English are led by experienced instructors who offer in-depth explanations, clear doubts, and guide you through the curriculum for success in your upcoming HSLC exams for both CBSE and SEBA boards.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row benefits-card">
                            <div className="col-3">
                                <div className='icon-area-benefits'>
                                    <i class="fa-solid fa-book"></i>
                                </div>

                            </div>
                            <div className="col-9">
                                <h3>Comprehensive Study Resources</h3>
                                <p>We know effective learning depends on the right tools. That's why we offer comprehensive chapter-wise notes for Mathematics, Science, and English. These materials enhance your understanding of key concepts, making your study sessions more efficient and productive as you prepare for your HSLC exams for both CBSE and SEBA boards.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="row benefits-card">
                            <div className="col-3">
                                <div className='icon-area-benefits'>
                                <i class="fa-brands fa-teamspeak"></i>
                                </div>

                            </div>
                            <div className="col-9">
                                <h3>Assessment and Exam Preparation</h3>
                                <p>Excelerator Batch goes beyond teaching to prepare you for HSLC success in both CBSE and SEBA boards. We offer essential resources, subject-specific mock tests, and focus on answer writing skills. Regular mock tests measure your progress, while performance updates keep parents informed and involved in your journey.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className='talent-exam py-5' id='test'>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <img src='/assets/accelerator/batch_test.jpg' alt='batch test' className='img-fluid'/>
                    </div>
                    <div className="col-md-6 right_side">
                        <h6>KNOW ABOUT</h6>
                        <h1>Excelerator Batch Selection Test!</h1>

                        <p>The Excelerator Batch Selection Test is your ticket to a world of opportunities! It's designed to identify exceptional talent among Assam's students, offering a pathway to Excelerator Batch, up to 100% scholarships, exclusive learning, and a proven 95% success rate. Don't miss your chance to shine and excel!</p>

                        <button className='btn-dwnld text-white' onClick={() => {
                            window.open("https://rzp.io/l/excelerator-batch")
                        }}>Register Now <i class="fa-solid fa-arrow-right"></i></button>
                        {/* <iframe name="leadsquared_landing_page_frame" src="https://live2.viewpage.co/Excelerator-Batch?ignoremxtracking=mxtrue" width="600" height="800" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe> <script type="text/javascript"> var MXLandingPageId = 'b6ed297d-efd9-11ed-a15e-0643ecf67fe6'; </script> */}
                    </div>
                </div>
            </div>
        </div>

        <div className='why-different py-5' id='success-stories'>
            <div className="container">
                <h6 className='text-center heading_helper'>EXCELERATOR BATCH</h6>
                <h1 className='heading text-center'>WHAT OUR TOPPERS SAY</h1>
                <div className="row mt-5 align-items-center">
                    <div className="col-md-4 order-2 order-sm-1">
                        <div className="card different-card">
                            <img src='/assets/accelerator/abhilash.png' alt='suhshita' className='img-fluid' style={{maxHeight: "300px", width: "100%"}}/>
                            <div className='mt-3'>
                                <h2 className="title">Abhilash Dadhara | 98.83 %</h2>
                                <p>EDUAID"- The name itself directs aiding one in the field of education. The speciality of EDUAID" is that it covers the potential in a student and converts it into success. "EDUAID" helps to mould every weak part of a student. Proud to be an "EDUAIDIAN</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 order-1 order-sm-2">
                        <div className="card different-card">
                            <img src='/assets/accelerator/sushmita.png' alt='suhshita' className='img-fluid' style={{maxHeight: "300px", width: "100%"}}/>
                            <div className='mt-3'>
                                <h2 className="title">Sushmita Poddar | 98.33% | 5th Rank| All Assam HSLC Examination</h2>
                                <p>Utilise all the sources you have and become a master in your learning journey. I would like to thank all the teachers for the doubt sessions they arranged for me especially. You get special personal attention and guidance with regular doubt-solving classes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 order-3 order-sm-3">
                        <div className="card different-card">
                            <img src='/assets/accelerator/ratandeep.png' alt='suhshita' className='img-fluid' style={{maxHeight: "300px", width: "100%"}}/>
                            <div className='mt-3'>
                                <h2 className="title">Ratnadeep Sen | 97.50 %</h2>
                                <p>According to me- Eduaid is one of the best online learning platforms for class 10 students. When they launched their app, I enrolled myself and it was the best decision. The classes in Eduaid are very good and it helped me to clear all my doubts and learn new concepts.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className="registration-process py-5" id='registration-process'>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h2 className='how-to'>How to Register</h2>

                        <div className="row mt-5">
                            <div className="col-md-6">
                                <h3 className='register-type'>Online Registration</h3>
                                <h6 className='desc'><a href="https://rzp.io/l/excelerator-batch" target='_blank'>Click Here</a> to register for the test!</h6>
                                <p className='extra_points'>* Online Mode Exam fees: Rs. 99</p>
                            </div>
                            <div className="col-md-6">
                                <h3 className='register-type'>Exam Dates</h3>
                                <h6 className='desc'>Mark your calendars for the EduAid Learning App's Selection Test on October 15, 18, and 29, 2023!</h6>
                                <p className='extra_points'>*Offline Registration is also available for this test, call 9477994779.</p>
                            </div>

                            <div className="col-md-6 mt-5">
                                <h3 className='register-type'>Exam Time and Duration</h3>
                                <h6 className='desc'>The test will take place on the specified dates, starting at 4:00 PM for 120 minutes, within the EduAid Learning App.</h6>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <img alt='registration process' src='/assets/accelerator/registration_process.svg' className='img-fluid'/>
                    </div>
                </div>
            </div>
        </div>

        <div className="registration-process py-5" id='syllabus'>
            <div className="container">
                <h1 className='process text-center'>Syllabus and Subjects:</h1>
                <p className='how-to text-center'>Get ready for success with the Excelerator Batch Selection Test for Class 10, covering<b> CBSE and SEBA half-yearly course curriculum, featuring English, Science, and Mathematics!</b></p>
            </div>
        </div>

        <div className="contact-us py-5" id='contact'>
            <h1 className='headline'>Contact Us</h1>

            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-4">
                    <div className="card different-card">
                            <div style={{width: "100%"}}>
                            <i class="fa-solid fa-phone"></i>
                                <h2 className="title">Phone</h2>
                                <a href='tel:9477994779' style={{color: "inherit"}}>+91 9477994779</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="card different-card">
                            <div style={{width: "100%"}}>
                            <i class="fa-solid fa-envelope"></i>
                                <h2 className="title">Email</h2>
                                <a href='mailto:info@eduaid.co.in' style={{color: "inherit"}}>info@eduaid.co.in</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                    <div className="card different-card">
                            <div style={{width: "100%"}}>
                            <i class="fa-solid fa-location-dot"></i>
                                <h2 className="title">Address</h2>
                                <p>3rd Divine Plaza, GS Rd, near Supermarket, Guwahati, Assam 781006</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AcceleratorBatch