import React, { useState } from 'react'
import { connect } from 'react-redux'

function PasswordModal(props) {
    const {loginWPwd, apiCalled, forgotPassword} = props
    const {user} = props

    const [showPassoword, setShowPassword] = useState(false)

    const [password, setPassword] = useState('')
    

    return (
        <>
            <div className="modal fade" id="pwdmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-ku">
                    <div className="modal-content">
                        <div className="modal-body m-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 pdg-0">
                                        <div className="otp-img">
                                            <img src="assets/images/ss.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-logo">
                                                    <div className="logo-otp">
                                                        <img src="assets/images/logoedu.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="codes2">Enter Password</p>
                                                    <p className="mbl-no1">
                                                        Please Enter the password which you added while
                                                        creating this account
                                                    </p>

                                                    <div className="pass--box">
                                                        <input type={showPassoword ? "text" : "password"} placeholder="Password" id="password"
                                                            className="form-control password_box" value={password} onKeyDown={(e) => {
                                                                if(e.code == "Enter" && !((apiCalled || user.type == 'request'))){
                                                                    loginWPwd(password)
                                                                }
                                                            }} onChange={(e) => setPassword(e.target.value)}/>
                                                        <i onClick={() => setShowPassword(!showPassoword)} className={`password-eyes ${showPassoword ? "fa-solid fa-eye" : "fa fa-eye-slash"}`} id="eye"
                                                            area-hidden="true"></i>
                                                    </div>
                                                    <p className="forgot" onClick={forgotPassword} style={{cursor: "pointer"}}>Forgot Password?</p>
                                                </div>
                                                <div className="mb-3 text-center">
                                                    <button disabled={(apiCalled || user.type == 'request')}  className={`btn btn-danger btnotp1 ${(apiCalled || user.type == 'request') ? "btn-req" : ""}`} onClick={() => {
                                                        loginWPwd(password)
                                                    }}>
                                                        {user.type == 'request' ? "Continuing..." : "Continue"}
                                                    </button>

                                                    <button id={"close-pwd-btn"} style={{display: "none"}} className="btn btn-danger rad-btn" data-bs-dismiss="modal">
                                                        Closess
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="privacy2">
                                                    <p>
                                                        By continuing you consent to share your information
                                                        with EduAid & agree to our <span>T&C</span> and
                                                        <span>Privacy Policy</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.user
  })
  
  const mapDispatchToProps = {
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PasswordModal)