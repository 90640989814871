import React, { useState } from 'react'
import { Alert } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { addDataToCart } from '../../services/actions';
import { connect } from 'react-redux';

function AddOnsModal(props) {
    const {open, handleClose, addOns, addAddon,currentAddOns} = props
    

  return (
    <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add ons</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{height: "400px",padding: "1rem", overflow: "auto"}}>
            {addOns?.map((a_o, index) => {
                return(
                    <div className="card mt-2" key={index}>
                        <div className="card-body p-2 d-flex justify-content-between align-items-center">
                            <div className='d-flex align-items-center'>
                                <img src={a_o.icon} alt={a_o.name} style={{width: "50px", height: "50px", borderRadius: "50%", border: "1px solid black"}}/>
                                <div className='ms-2'>
                                  <h6>{a_o.name}</h6>
                                  <p className='mb-0'>₹ {a_o.price} /-</p>
                                </div>
                            </div>
                            {currentAddOns.includes(a_o) ? 
                            <button className='btn btn-danger' onClick={() => {
                              addAddon('remove',a_o)
                              props.addDataToCart('remove',a_o)
                            }}>
                                Remove -
                            </button>
                            :
                            <button className='btn btn-primary' onClick={() => {
                              addAddon('add',a_o)
                              props.addDataToCart('add',a_o)
                            }}>
                                Add +
                            </button>
                            }
                        </div>
                    </div>
                )
            })}

            
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
  addDataToCart
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOnsModal);
