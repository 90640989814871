import React from 'react'

function Champions() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="row gttr">
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-4 col-6">
                <div className="ch-div">
                  <div className="champ-img">
                    <img
                      src="assets/images/avatar-1.png"
                      className="img-fluid"
                      alt=""
                    />
                    <p className="tenth">10<sup>th</sup></p>
                  </div>
                  <h6 className="albart">Albart Roz</h6>

                  <div className="str-flex">
                    <p className="perc">70.6%</p>
                    <p className="icon-str">
                      <i className="fa fa-star"></i>
                      <span>4.5</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  )
}

export default Champions