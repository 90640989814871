const initialState = {
    type:"",
    course: {},
    items:[],
    message:"",
    coupon_code: "",
    addOnTotal: 0
  }
  
  export function  cartReducer(state =initialState, action){
    let obj;
    let tmp = [];
     obj = {...state};
    // eslint-disable-next-line default-case
    switch (action.type) {
        case "GET_CART_SUCCESS":
            return  {...state}
        case "ADD_COURSE_SUCCESS":
            return {...state, course: action.data, type: "success"}
        case "ADD_TO_CART_SUCCESS":
            let cData = [...obj.items]
            cData.push(action.data)
            return  {...state,items:cData, addOnTotal: state.addOnTotal + action.data.price, type: "success"}
        case "REMOVE_FROM_CART_SUCCESS":
            return  {...state,items: obj.items.filter((item) => item.id != action.data.id), addOnTotal: state.addOnTotal - action.data.price , type: "success"}
        case "ADD_COURSE_SUCCESS":
            return {...state, items: action.data.items, course: action.data.course, type: "success",addOnTotal: action.data.addOnTotal, coupon_code: action.data.coupon_code }
        default:
            return state
    }
    return state;
  }
  