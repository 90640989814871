import React, { useEffect, useState } from 'react'
import { getBatchesData, getStudentBatchesData } from '../../utils/admin'

function BatchesComp() {
  const [batchesData, setBatchesData] = useState({type: "", data: []})
  const [studentBatchesData, setStudentBatchesData] = useState({type: "", data: []})

  const getBatches = () => {
    setBatchesData({...batchesData, type: "request"})
    getBatchesData((res) => {
      if(res.type == "success"){
        setBatchesData({type: "success", data: res.data})
      }else{
        setBatchesData({...batchesData, type: "error"})
      }
    })


    getStudentBatchesData((res) => {
      setStudentBatchesData({...studentBatchesData, type: "request"})
      if(res.type == "success"){
        setStudentBatchesData({type: "success", data: res.data})
      }else{
        setStudentBatchesData({...studentBatchesData, type: "error"})
      }
    })
  }

  useEffect(() => {
    getBatches()
  }, [])

  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="text-center top-input mb-3">
            <button><i className="fa-solid fa-magnifying-glass"></i></button><input type="text" placeholder="Search" />
          </div>
          <div className="container-fluid new-div">
            <div className="row">
              <div className="col-md-8 col-sm-8">
                <div className="all-h1-p">
                  <h1>You Can Now Try Our Courses</h1>
                  <p className="text-white f-famaly">
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout.
                  </p>
                  <button>Try Now</button>
                </div>
              </div>
              <div className="col-md-4 col-sm-4">
                <div className="model-img">
                  <img src="/assets/images/model-1.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
            <div className="col-md-12">
                <h3 className="batch-heading">Student Batches</h3>
              </div>
              {studentBatchesData.type == "request" ?
                <h3 className='text-center'>Loading...</h3>
                :
                studentBatchesData.type == "success" &&
                  studentBatchesData.data.map((stu_data,index) => {
                    return(
                      <div className="col-md-6" key={index}>
                        <div className="card batch-card">
                          <img
                            src="/assets/images/Downloader.png"
                            className="card-img-top img-fluid"
                            alt="..."
                          />
                          <div className="card-body tenbody">
                            <h5 className="card-title c-tenth">
                              <i className="fa fa-graduation-cap"></i> {stu_data.batch_name}
                            </h5>
                            <p className="card-text card-p">
                              <i className="fa fa-calendar"></i><span className="starton">Starts on</span><b>24 April, 2023</b>
                              <span className="starton">| End on</span>
                              <b>24 May, 2023</b>
                            </p>
                            <p className="related">
                              <i className="bx bxs-star"></i>Related Lecture | Pdf class
                              Notes
                            </p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              <div className="col-md-12">
                <h3 className="batch-heading">Batches</h3>
              </div>
              {batchesData.type == "request" ?
                <h3 className='text-center'>Loading...</h3>
                :
                batchesData.type == "success" &&
                batchesData.data.map((b_data, index) => {
                  return(
                    <div className="col-md-6" key={index}>
                      <div className="card batch-card">
                        <img
                          src="/assets/images/Downloader.png"
                          className="card-img-top img-fluid"
                          alt="..."
                        />
                        <div className="card-body tenbody">
                          <h5 className="card-title c-tenth">
                            <i className="fa fa-graduation-cap"></i> {b_data.batch_name}
                          </h5>
                          <p className="card-text card-p">
                            <i className="fa fa-calendar"></i><span className="starton">Starts on</span><b>24 April, 2023</b>
                            <span className="starton">| End on</span>
                            <b>24 May, 2023</b>
                          </p>
                          <p className="related">
                            <i className="bx bxs-star"></i>Related Lecture | Pdf class
                            Notes
                          </p>
                          <div className="card-button expl-flex">
                            <a href="javascript:void(0)" className="btn btn-primary">Explore</a>
                            <a href="javascript:void(0)" className="btn btn-primary">Enroll</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              {/* <div className="col-md-12">
                <div className="blank-div-flex">
                  <div className="blank-div"></div>
                  <div className="blank-div"></div>
                  <div className="blank-div"></div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
  )
}

export default BatchesComp