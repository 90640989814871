import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Form } from 'react-bootstrap'

import { setBoardId, setStandardId } from '../../services/actions'

function GoalModal(props) {
    const {setGoalHandler, apiCalled} = props

    
    const {user,boards} = props
    const [boardName, setBoardName] = useState('')
    const [standards, setStandards] = useState([])

    const [error, setError] = useState(false)

    const [boardId, setBoardId]= useState('')
    const [standardId, setStandardId] = useState('')

    const changeBoardHandler = async (id) => {
        let standards = await axios.get(`/api/v3/s/get-standards-by-board/?board_id=${id}`)
        setBoardId(id)
        props.setBoardId(id)

        setStandards(standards.data.response.data.standards)
    }

    return (
        <>
            <div className="modal fade" id="goalmodal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-ku">
                    <div className="modal-content">
                        <div className="modal-body m-body">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 pdg-0">
                                        <div className="otp-img">
                                            <img src="assets/images/goalimg.png" className="img-fluid" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="d-logo">
                                                    <div className="logo-otp">
                                                        <img src="assets/images/logoedu.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="mb-3">
                                                    <p className="codes2 goal-h">My Goal is</p>
                                                </div>
                                                <div className="school">
                                                    <h6>Excelling in School</h6>
                                                    <div className="option-flex">
                                                        
                                                        {boards.type == 'success' &&
                                                            boards.data.map((b, index) => {
                                                                return (
                                                                    <div key={index} className='radio-box'>
                                                                        <div>
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="exampleRadios" id={`check-${index}`} value="option1" onChange={(e) => {
                                                                                    setError(false)
                                                                                    if(e.target.checked){
                                                                                        setBoardName(b.name)
                                                                                        changeBoardHandler(b.id)
                                                                                    }
                                                                                }} checked={boardName == b.name}/>
                                                                                <label className="form-check-label" htmlFor={`check-${index}`}>
                                                                                {b.name}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                {standards.length > 0 ? 
                                                <div className="school mt-3">
                                                    <h6 style={{fontWeight: 600}}>My class is</h6>
                                                    <div className="option-flex" style={{justifyContent: "center"}}>
                                                        {standards.map((s, index) => {
                                                            return (
                                                            <div key={index} className="radio-box rad-pdg" style={{margin: 6}}>
                                                            <div>
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="radio" name="standard" id={`stan-${index}`} value="option1" onChange={(e) => {
                                                                        setError(false)
                                                                        if(e.target.checked){
                                                                            setStandardId(s.id)
                                                                            props.setStandardId(s.id)
                                                                        }
                                                                    }} checked={standardId == s.id}/>
                                                                    <label className="radio-inline" htmlFor={`stan-${index}`}>
                                                                    {s.name_slug}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        )
                                                        })}
                                                    </div>
                                                </div>
                                                :
                                                <div className="school mt-3">
                                                    <h6 style={{fontWeight: 600, textAlign: "center"}}>Please select a board first</h6>
                                                </div>
                                                }

                                                <div className="mb-3 text-center">
                                                    <button disabled={apiCalled || error || user.type == 'request'} className={`btn btn-danger rad-btn ${(error || apiCalled || user.type == 'request' ? "btn-req" : "")}`} onClick={() => {
                                                        if(standardId == "" || standardId == null){
                                                            setError(true)
                                                            return
                                                        }
                                                        setGoalHandler(standardId)
                                                    }}>
                                                            {/* <button className="btn btn-danger rad-btn" data-bs-toggle="modal"
                                                        data-bs-target="#callingmodal" data-bs-dismiss="modal"></button> */}
                                                        {(user.type == 'request' || apiCalled) ? "Continuing..." : "Continue"}
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="privacy4">
                                                    <p>
                                                        By continuing you consent to share your information
                                                        with EduAid & agree to our <span>T&C</span> and
                                                        <span>Privacy Policy</span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
const mapStateToProps = (state) => ({
    user: state.user,
    boards: state.boards
})
  
const mapDispatchToProps = {
    setBoardId,
    setStandardId
}
  
  export default connect(mapStateToProps, mapDispatchToProps)(GoalModal)