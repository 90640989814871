import { combineReducers } from 'redux';
import {userReducer} from './user';
import { subscriptionReducer } from './subscriptions';
import { boardReducer } from './boards';
import { cartReducer } from './cart';
import { adminReducer } from './admin';
import { eduhelperReducer } from './eduhelper';

const rootReducer = combineReducers({
    user: userReducer,
    subscription: subscriptionReducer,
    boards: boardReducer,
    cart: cartReducer,
    admin: adminReducer,
    eduhelper: eduhelperReducer
});

export default rootReducer;