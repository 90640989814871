import React, { useState } from 'react'
import { loginEduHelper } from '../../services/actions'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'

function EduhelperLogin(props) {
    const [formData, setFormData] = useState({
        username: "",
        password: ""
    })

    const navigate = useNavigate()

    const handleChange = (type, val) => {
        let existing_data = {...formData}
        existing_data[type] = val

        setFormData(existing_data)
    }

    const loginHandler = (e) => {
        e.preventDefault()

        props.loginEduHelper(formData, (res) => {
            if(res.type == "success"){
                navigate('/eduhelper/dashboard')
            }
        })
    }

  return (
    <section className='eduhelper-login'>
        <div className="card" style={{width: "600px", maxWidth: "100%"}}>
            <div className="card-body">
                <h5 className="card-title">Login as Eduhelper</h5>
                <form className='mt-4' onSubmit={loginHandler}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label">Username</label>
                        <input type="text" className="form-control" id="username" name='username' value={formData.username
                        } onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label">Password</label>
                        <input type="password" className="form-control" id="password" name='password' value={formData.password
                        } onChange={(e) => handleChange(e.target.name, e.target.value)}/>
                    </div>
                    <button type="submit" className="btn btn-primary">Login</button>
                </form>
            </div>
        </div>
    </section>
  )
}

const mapStateToProps = (state) => ({
  });
  
  const mapDispatchToProps = {
    loginEduHelper
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(EduhelperLogin);
  

