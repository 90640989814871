const initialState = {
  type:"",
  login:false,
  data:{},
  message:""
}

export function  userReducer(state =initialState, action){
  let obj;
  let tmp = [];
   obj = {...state};
  // eslint-disable-next-line default-case
  switch (action.type) {
    case "RESET_USER":
      return  {type:"",login:false,data:{},message:""}
    case "LOGIN_REQUEST":
      return {...state,type:"request"}
    case "LOGIN_SUCCESS":
      return {...state, type: 'success'}
    case "LOGIN_SUCCESS_FULL":
      return {...state,login: true, type: 'success', data: action.data}
    case "LOGIN_ERROR":
      return {...state, type: 'error', message: action.message}
    case "LOGIN_TYPE_RESET":
      return {...state, type: "", message: ""}
    case "USER_SET_SUCCESS":
      return {...state, data: action.data}
    case "CHANGE_STANDARD_ID":
      let newData = {...obj}
      newData['data'] = {...newData['data'], standard_id: action.id}

      return {...state, data: newData.data}

    default:
      return state
  }
  return state;
}
