import React from 'react'
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

function AboutUs(props) {
    const {user} = props
    

  return (
    <>
          <Helmet>‍
        <title>About EduAid Company</title>‍
        <meta name="description" content="let about" /> 
        <meta name="twitter:card" content="summary_large_image" />        
        <meta name="twitter:site" content="@user" />        
        <meta name="twitter:creator" content="@user" />        
        <meta name="twitter:title" content="Pets - Products" />        
        <meta name="twitter:description" content="Best Products for your pet" />        
        <meta name="twitter:image" content="url_to_image"/>        

      </Helmet>  
        <section>
        <div className="bigimage">
            <div className="bgoverlay bg-bread">
                <nav className="nav-position" style={{"--bs-breadcrumb-divider": '>'}} aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="./index.html">Home</a>
                        </li>
                        <li className="breadcrumb-item text-white active" aria-current="page">
                            About Us
                        </li>
                    </ol>
                </nav>
                <h3>About Us</h3>
            </div>
        </div>
    </section>
    <section className="tut-margn">
        <div className="container">
            <div className="cont-border-radius-demo online-pdg">
                <div className="row row-flex-icon">
                    <div className="col-md-6">
                        <div className="tutor-box">
                            <h5>Be the change you wish to see in the world</h5>
                            <h6>Three common people, one big dream!</h6>
                            <p>
                            Three individuals, Mr. Samujjal Barooah, Mr. Vineet Soni, and Mr. Himadri Borpuzari, founded EduAid in 2018 with a shared vision to provide quality education for all Northeast students. They addressed the disparity in learning opportunities and became the catalyst for positive change, making their dream a reality.
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="abt-icon-box">
                                    <div>
                                        <img src="/assets/images/abt2.png" alt="" />
                                    </div>
                                    <h6>1st</h6>
                                    <p>Ed-tech Startup in NE</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="abt-icon-box bg-color-2">
                                    <div>
                                        <img src="/assets/images/abt1.png" alt="" />
                                    </div>
                                    <h6>10000</h6>
                                    <p>plus Downloads</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="abt-icon-box bg-color-3">
                                    <div>
                                        <img src="/assets/images/abt3.png" alt="" />
                                    </div>
                                    <h6>100%</h6>
                                    <p>Result Driven</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="abt-ed">
                        <h4>About EduAid</h4>
                        <p>
                        EduAid Learning App is an innovative platform that empowers students with quality education. With a user-friendly interface, it offers a wide range of subjects and resources, making learning engaging and accessible. Expert teachers and interactive features enhance the learning experience, helping students excel in their studies and reach their full potential.
                            <br />
                            SIQES EDUAID LLP(EduAid Learning For All) is a startup spin-off from the renowned parent group SHASS, which has a rich history of over 30 years across various sectors such as construction, the SHASS group is a prominent construction firm engaged in government projects, including PWD, Railways, MES, Power Grid Corporation, and real estate development.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="cont-border-radius-abt">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pdg-series">
                            <div className="col-md-2 col-sm-3">
                                <div className="notes-img">
                                    <img src="/assets/images/accessibility.png" className="img-fluid" alt="Accessibility" />
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-9">
                                <div className="series-box">
                                    <h4>Accessibility</h4>
                                    <p>
                                    EduAid Learning App bridges the gap by providing accessible educational resources anytime and anywhere.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cont-border-radius-abt">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pdg-series">
                            <div className="col-md-2 col-sm-3">
                                <div className="notes-img">
                                    <img src="/assets/images/personalizedlearning.png" className="img-fluid" alt="Personalized Learning" />
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-9">
                                <div className="series-box">
                                    <h4>Personalized Learning</h4>
                                    <p>
                                    We customize your learning journey using complex algorithms, to meet your needs, styles, and paces for effective learning.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cont-border-radius-abt">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pdg-series">
                            <div className="col-md-2 col-sm-3">
                                <div className="notes-img">
                                    <img src="/assets/images/engagementandMotivation.png" className="img-fluid" alt="Engagement and Motivation" />
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-9">
                                <div className="series-box">
                                    <h4>Engagement and Motivation</h4>
                                    <p>
                                    Gamification with interactive quizzes and multimedia content make learning engaging and fun, keeping you motivated and interested in your studies.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cont-border-radius-abt">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pdg-series">
                            <div className="col-md-2 col-sm-3">
                                <div className="notes-img">
                                    <img src="/assets/images/flexibilityandconvenience.png" className="img-fluid" alt="Flexibility and Convenience" />
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-9">
                                <div className="series-box">
                                    <h4>Flexibility and Convenience</h4>
                                    <p>
                                    EduAid Learning App enables you to study at your own pace, offering flexibility in scheduling and time management.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cont-border-radius-abt">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pdg-series">
                            <div className="col-md-2 col-sm-3">
                                <div className="notes-img">
                                    <img src="/assets/images/costeffective.png" className="img-fluid" alt="Cost-Effective" />
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-9">
                                <div className="series-box">
                                    <h4>Cost-Effective</h4>
                                    <p>
                                    Compared to traditional educational methods, EduAid is your pocket-friendly partner, reducing the need for physical textbooks and classroom materials.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cont-border-radius-abt">
                <div className="row">
                    <div className="col-md-12">
                        <div className="row pdg-series">
                            <div className="col-md-2 col-sm-3">
                                <div className="notes-img">
                                    <img src="/assets/images/accesstoexpertise.png" className="img-fluid" alt="Access to Expertise" />
                                </div>
                            </div>
                            <div className="col-md-10 col-sm-9">
                                <div className="series-box">
                                    <h4>Access to Expertise</h4>
                                    <p>
                                    You can access expert teachers and counselors from diverse backgrounds and geographies, gaining insights and knowledge beyond their immediate location.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section className="trial-sec">
        <div className="bigimage-btm">
            <div className="bgoverlay-btm">
                <h3>Book a free demo class</h3>
                {!user.login &&
                    <button className="btn btn-danger buy-now-btn" onClick={() => {
                        document.getElementById(`login-btn`).click()
                    }}>Book Now</button>
                }
            </div>
        </div>
    </section>
    </>
  )
}

const mapStateToProps = (state) => ({
    user: state.user,
  });
  
  const mapDispatchToProps = {
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
  