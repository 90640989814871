import React from 'react'

function StudyComp() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="text-center top-input">
                  <button><i className="fa-solid fa-magnifying-glass"></i></button
                  ><input type="text" placeholder="search" />
                </div>
                <div>
                  <div className="cont">
                    <h1>Continue Learning</h1>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="pr-video">
                  <video
                    className="video-box ifram"
                    src="/assets/video/video1.mp4"
                    poster="/assets/images/continue.png"
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cont">
                  <h1>Live Class</h1>
                </div>
              </div>
              <div className="col-md-4">
                <div className="pr-video">
                  <video
                    className="video-box ifram"
                    src="/assets/video/video1.mp4"
                    poster="/assets/images/live1.png"
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="p-new mb">Class 10th : Math</p>
              </div>
              <div className="col-md-4">
                <div className="pr-video">
                  <video
                    className="video-box ifram"
                    src="/assets/video/video1.mp4"
                    poster="/assets/images/live2.png"
                    controls
                  >
                    Your browser does not support the video tag.
                  </video>
                </div>
                <p className="p-new">Class 10th : Math</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cont">
                  <h1>Upcoming Live Class</h1>
                </div>
              </div>
              <div className="col-md-12">
                <div className="slick-slider">
                  <div className="element element-1">
                    <div className="pr-video">
                      <video
                        className="video-box ifram"
                        src="/assets/video/video1.mp4"
                        poster="/assets/images/u1.png"
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p className="p-new">Class 10th : Math</p>
                  </div>
                  <div className="element element-2">
                    <div className="pr-video">
                      <video
                        className="video-box ifram"
                        src="/assets/video/video1.mp4"
                        poster="/assets/images/u2.png"
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p className="p-new">Class 10th : Math</p>
                  </div>
                  <div className="element element-3">
                    <div className="pr-video">
                      <video
                        className="video-box ifram"
                        src="/assets/video/video1.mp4"
                        poster="/assets/images/u3.png"
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p className="p-new">Class 10th : Math</p>
                  </div>
                  <div className="element element-4">
                    <div className="pr-video">
                      <video
                        className="video-box ifram"
                        src="/assets/video/video1.mp4"
                        poster="/assets/images/u1.png"
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p className="p-new">Class 10th : Math</p>
                  </div>
                  <div className="element element-5">
                    <div className="pr-video">
                      <video
                        className="video-box ifram"
                        src="/assets/video/video1.mp4"
                        poster="/assets/images/u2.png"
                        controls
                      >
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p className="p-new">Class 10th : Math</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cont">
                  <h1 className="notes-mr">Subject Concept</h1>
                </div>
              </div>
              <div className="col-md-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item item-hover math-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Maths
                    </button>
                  </li>
                  <li className="nav-item item-hover bio-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Bio
                    </button>
                  </li>
                  <li className="nav-item item-hover phy-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Physics
                    </button>
                  </li>
                  <li className="nav-item item-hover eng-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-eng"
                      type="button"
                      role="tab"
                      aria-controls="pills-eng"
                      aria-selected="false"
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item item-hover che-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-che"
                      type="button"
                      role="tab"
                      aria-controls="pills-che"
                      aria-selected="false"
                    >
                      Chemistry
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                    tabIndex="0"
                  >
                    <div className="row row-two">
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (1).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (3).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart2"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (2).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart3"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (4).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart4"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                    tabIndex="0"
                  >
                    Bio
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                    tabIndex="0"
                  >
                    Physics
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-eng"
                    role="tabpanel"
                    aria-labelledby="pills-eng-tab"
                    tabIndex="0"
                  >
                    English
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-che"
                    role="tabpanel"
                    aria-labelledby="pills-che-tab"
                    tabIndex="0"
                  >
                    Chemistry
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row row-two">
              <div className="col-md-6">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (1).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (3).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (2).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (4).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (5).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (6).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mb-4">
                <div className="main-div-topic">
                  <img
                    src="/assets/images/Frame (7).png"
                    className="img-fluid"
                    alt=""
                  />

                  <h2>Topic</h2>
                  <a href="#">
                    <img
                      type="button"
                      className="video-btn img-fluid"
                      data-bs-toggle="modal"
                      data-src="https://www.youtube.com/embed/Jfrjeg26Cwk"
                      data-bs-target="#myModal"
                      src="/assets/images/playbtn.png"
                      alt=""
                  /></a>
                  <div className="main-div-two">
                    <p><span>Duration :</span> 2 hour 10 mint</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="cont">
                  <h1 className="notes-mr">Notes</h1>
                </div>
              </div>
              <div className="col-md-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item item-hover math-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-mathsnotes-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-mathsnotes"
                      type="button"
                      role="tab"
                      aria-controls="pills-mathsnotes"
                      aria-selected="true"
                    >
                      Maths
                    </button>
                  </li>
                  <li className="nav-item item-hover bio-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-bionotes-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-bionotes"
                      type="button"
                      role="tab"
                      aria-controls="pills-bionotes"
                      aria-selected="false"
                    >
                      Bio
                    </button>
                  </li>
                  <li className="nav-item item-hover phy-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-phynotes-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-phynotes"
                      type="button"
                      role="tab"
                      aria-controls="pills-phynotes"
                      aria-selected="false"
                    >
                      Physics
                    </button>
                  </li>
                  <li className="nav-item item-hover eng-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-engnotes-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-engnotes"
                      type="button"
                      role="tab"
                      aria-controls="pills-engnotes"
                      aria-selected="false"
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item item-hover che-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-chenotes-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-chenotes"
                      type="button"
                      role="tab"
                      aria-controls="pills-chenotes"
                      aria-selected="false"
                    >
                      Chemistry
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-mathsnotes"
                    role="tabpanel"
                    aria-labelledby="pills-mathsnotes-tab"
                    tabIndex="0"
                  >
                    <div className="row row-two">
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (1).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart5"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (2).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart6"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (3).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart7"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="/assets/images/Frame (4).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart8"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-bionotes"
                    role="tabpanel"
                    aria-labelledby="pills-bionotes-tab"
                    tabIndex="0"
                  >
                    Notes Bio
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-phynotes"
                    role="tabpanel"
                    aria-labelledby="pills-phynotes-tab"
                    tabIndex="0"
                  >
                    Notes Physics
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-engnotes"
                    role="tabpanel"
                    aria-labelledby="pills-engnotes-tab"
                    tabIndex="0"
                  >
                    Notes English
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-chenotes"
                    role="tabpanel"
                    aria-labelledby="pills-chenotes-tab"
                    tabIndex="0"
                  >
                    Notes Chemistry
                  </div>
                </div>
              </div>
            </div>

            <div className="row row-two">
              <div className="col-md-12">
                <div className="note-flex">
                  <h4>Notes</h4>
                  <div>
                    <label className="filelabel">
                      <img
                        src="/assets/images/uploadicon.png"
                        className="img-fluid"
                        alt=""
                      />
                      <input className="FileUpload1" id="" name="" type="file" />
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="uploadsec">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Eligendi quae accusamus quisquam qui ratione, iusto totam
                    magnam veniam molestiae ad recusandae illo, vero minima est
                    quos fuga architecto, ullam cumque. Cumque, eveniet fugiat?
                    Quaerat possimus ea ratione officia alias. Consequatur
                    reiciendis ut odio totam hic, nostrum, quia quis in harum
                    unde assumenda labore sit placeat a odit minus ea et! Quia
                    expedita aut illo libero maiores eaque accusamus
                    consequuntur in culpa itaque ullam numquam, excepturi fuga
                    inventore facere dolorum dignissimos fugiat, perferendis
                    dolores perspiciatis minima nulla temporibus! Quod, neque
                    tempore est commodi beatae eligendi quis ipsam illum
                    cupiditate, eum deserunt.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default StudyComp