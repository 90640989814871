import axios from 'axios';
import React, { useEffect, useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

import useRazorpay from "react-razorpay";
import CouponCodeModal from '../../components/modals/couponCode';
import AddOnsModal from '../../components/modals/addOns';
import { verifyYourPayement } from '../../utils';
import { connect } from 'react-redux';

function BuyPlan(props) {
    const {cart} = props
    const navigate = useNavigate()
    const { state } = useLocation();
    const [pageData, setPageData] = useState(state.data.pageData)

    const addOns = state.data.addOns

    const [discountPercent, setDiscountPercent] = useState('')

    const [openCoupon, setOpenCoupon] = useState(false)
    const [openAddOn, setOpenAddOn] = useState(false)

    const [courseBought, setCourseBought] = useState(false)

    const Razorpay = useRazorpay();

    const [apiCalled, setApiCalled] = useState({status: false, type: "checkout"})

    const [availableCoupons, setAvailableCoupons] = useState([])
    
    const [currentAddOns, setCurrentAddOns] = useState([])

    const [cCode, setCCode] = useState('')

    const [cPrice,setCPrice] = useState(pageData.price)

    const getCouponsData = async () => {
        let getCoupons = await axios.get('/api/v3/s/coupons/')

        if(getCoupons.data.status == 'error'){
          // toast.error(getCoupons.data.response.statusDescription)
        }else{
          setAvailableCoupons(getCoupons.data.response.data.coupon)
        }
    }

    const addAddon = (type,data) => {
      if(type == 'add'){
        setCurrentAddOns(prev => {
          return [...prev, data]
        })
        setCPrice(cPrice + data.price)
      }else{
        // setCurrentAddOns()
        setCPrice(cPrice - data.price)
        setCurrentAddOns(currentAddOns.filter((item) => item != data))
      }
    }

    const verifyCoupon = async () => {
        setApiCalled({status: true, type: "verify"})
        let data = {
            coupon_code: cCode,
            plan_id: pageData.id
        }
        let verifyC = await axios.post('/api/v3/s/verify-coupon/', {...data})
        setApiCalled({status: false, type: ""})

        if(verifyC.data.status == "error"){
          // toast.error(verifyC.data.response.statusDescription)
        }else{
          setDiscountPercent(verifyC.data.response.data.discount_percent)
          // toast.success(verifyC.data.response.statusDescription)
        }
    }

    const loadRazorPay = (src) => {
      return new Promise(resolve => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
          resolve(true)
        }
        script.onerror = () => {
          resolve(false)
        }
        document.body.appendChild(script)
      })
    }

    const verifyPayement = (data) => {
      verifyYourPayement(data, (res) => {
        if(res.type == "error"){
          // toast.error(res.message)
        }else{
          // toast.success(res.message)
          setCourseBought(true)
        }
      })
    }

    const showRazorPay = async (data) => {

      const {preload_data,amount,razorpay_order_id,order_id,ref_id } = data

      const res = await loadRazorPay('https://checkout.razorpay.com/v1/checkout.js')
      
      if(!res){
        alert("Something went wrong")
        return
      }

      var options = {
          "key": process.env.REACT_APP_RAZORPAY_PUB_KEY_TEST, // Enter the Key ID generated from the Dashboard
          "amount": amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
          "currency": "INR",
          "name": "Eduaid", //your business name
          "description": "Buy course plan",
          "image": null,
          "order_id": razorpay_order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
          "callback_url": "http://localhost:3000/buyPlan",
          handler: function(resolve) {
            verifyPayement(resolve)
          },
          "prefill": { //We recommend using the prefill parameter to auto-fill customer's contact information especially their phone number
              "name": "Darshan mundra", //your customer's name
              "email": "developer.darshanmundra@gmail.com",
              "contact": "8955569278" //Provide the customer's phone number for better conversion rates 
          },
          "notes": {
              "address": "Jodhpur, Rajasthan"
          },
          "theme": {
              "color": "#3399cc"
          }
      };
      var rzp1 = new Razorpay(options);
      rzp1.open();
    
    }

    const initiateCheckout = async () => {
      let modules = []
      for (let i = 0; i < currentAddOns.length; i++) {
        modules.push(currentAddOns[i].id)
      }

      let data = {
        plans: pageData.id,
        coupon_code: cCode,
        modules
      }
      setApiCalled({status: true, type: "checkout"})

      let chekOut = await axios.post('/api/v3/s/checkout-v2/', {...data}, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })

      setApiCalled({status: false, type: ""})
      if(chekOut.data.status == 'error'){
        // toast.error(chekOut.data.response.statusDescription)
      }else{
        showRazorPay(chekOut.data.response.data)
      }
      return

      if(chekOut.data.status == 'error'){
        // toast.error(chekOut.data.response.statusDescription)
      }else{
        // toast.success(chekOut.data.response.statusDescription)
      }


    }

    useEffect(() => {
        getCouponsData()
    }, [])

  return (
    <>
    <ToastContainer />
        <section>
        <div className="bigimage-become">
          <div className="bgoverlay-become bg-bread">
            <nav
              className="nav-position"
              style={{"--bs-breadcrumb-divider": ">"}}
              aria-label="breadcrumb"
              >
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item text-white active" aria-current="page">
                  Purchase a plan
                </li>
              </ol>
            </nav>
            <h3>Purchase a plan</h3>
          </div>
        </div>
    </section>

    <div className="container mar-top">
      <div className="row">
        <div className="col-md-5">
          <div className="card-bg-img-chkout">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="crown-box4">
                  <img src="assets/images/crown-yellow.png" alt="" />
                  <span>{pageData.name}</span>
                </div>
                <div className="grand-box4">
                  <p>Be the grand master with every features unlocked !!</p>
                  <h6>Mentorship...</h6>
                  <a href="javascript:void(0)" className="">Read More...</a>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="recommend4">
                  <h5>RECOMMENDED</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        

        <div className="col-md-7">
          <div className="elt-box">
            <h4 className="el-ten">{pageData.name}</h4>
            <div className="star-box-flex">
              <div className="stars">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
              </div>
            </div>

            <h6>Course price: ₹{pageData.price}</h6>
            {currentAddOns.length > 0 &&
              <div>
                <h5 style={{textDecoration: "underline"}}>Add ons - </h5>
                {currentAddOns.map((c_addon, index) => {
                  return(
                    <h6>{c_addon.name}: ₹{c_addon.price}</h6>
                  )
                })}
              </div> 
            }
            <div className="rs-box-flex">
              <p className="hundred"
              >Total: ₹{discountPercent != '' ? (cPrice * (discountPercent / 100)) :cPrice}</p>
              {discountPercent != '' &&
                <div className="off-flex">
                  <p className="fiveth"><s>₹ {cPrice}</s></p>
                  <p className="offtwo">{discountPercent}% Off</p>
                </div>
              }
            </div>

            <div>
              <button className="btn btn-info btn-add" onClick={() => setOpenAddOn(true)}> + Add On</button>
            </div>

            <div className="input-flex">
              <input
                type="text"
                id=""
                className="form-control code-input"
                aria-describedby=""
                placeholder="code"
                value={cCode}
                onChange={(e) => setCCode(e.target.value)}
                disabled={discountPercent != ''}
              />
              <div>
              {discountPercent == '' &&
                <button className="btn btn-outline-primary btn-cpn" disabled={(apiCalled.status && apiCalled.type == "verify" || cCode == "")} onClick={verifyCoupon}> {(apiCalled.status && apiCalled.type == "verify") ? "Applying..." : "Apply Coupon"} </button>
              }
              {discountPercent == '' && 
                <button className="btn text-primary" onClick={() => setOpenCoupon(true)}> Show Coupons</button>
              }
              </div>
            </div>
            <div>
              {courseBought ?
              <></>
              :
              // <button disabled={(apiCalled.status && apiCalled.type == "checkout")} onClick={initiateCheckout} className="btn btn-success">
              // <i className="fa-solid fa-cart-shopping"></i>Cart</button>
              <button disabled={(apiCalled.status && apiCalled.type == "checkout")} onClick={() => {
                navigate('/cart', {state:  {data: {pageData, addOns}}})
              }} className="btn btn-success">
              <i className="fa-solid fa-cart-shopping"></i>Cart</button>

            }
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <h5 className="faq-title">FAQ</h5>
          <p className="faq-para">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page editors now use Lorem Ipsum as their default
            model text, and a search for 'lorem ipsum' will uncover many web
            sites still in their infancy. Various versions have evolved over the
            years, sometimes by accident, sometimes on purpose (injected humour
            and the like).
          </p>
          <p className="faq-para">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English. Many desktop publishing
            packages and web page.
          </p>
          <p className="faq-para">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here, content
            here', making it look like readable English.
          </p>
        </div>
      </div>
    </div>
    {openCoupon &&
      <CouponCodeModal open={openCoupon} coupons={availableCoupons} handleClose={() => setOpenCoupon(false)}/>
    }
    {openAddOn &&
      <AddOnsModal open={openAddOn} addOns={addOns} currentAddOns={currentAddOns} addAddon={addAddon} handleClose={() => setOpenAddOn(false)}/>
    }
    </>
  )
}

const mapStateToProps = (state) => ({
  cart: state.cart
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(BuyPlan);
