import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { applyAsaTeacher } from '../../utils'

function BecomeATeacherModal(props) {
    const {open, handleClose} = props
    const [apiCalled, setApiCalled] = useState(false)

    const {toast} = props


    const [formData, setFormdata] = useState({
        first_name: "",
        last_name: "",
        address: "",
        gender: "",
        phone: "",
        email_id: "",
        teaching_experience: ""
    })
    const [cv, setCv] = useState({})

    const setFilesData = (e) => {
        if(e.target.files){
            setCv(e.target.files[0])
        }
    }

    const handleFormDataChange = (type, val) => {
        let existing_data = {...formData}
        existing_data[type] = val

        setFormdata(existing_data)
    }

    const applyAsTeacher = (e) => {
        e.preventDefault()
        let data = {
            ...formData,
            cv
        }

        setApiCalled(true)
        applyAsaTeacher(data, (res) => {
            setApiCalled(false)
            if(res.type == "error"){
                // toast.error(res.message)
            }else{
                // toast.success(res.message)
                setCv({})
                setFormdata({
                    first_name: "",
                    last_name: "",
                    address: "",
                    gender: "",
                    phone: "",
                    email_id: "",
                    teaching_experience: ""
                })

                handleClose()
            }
        })
    }


  return (
    <Modal className='teacher-modal' style={{width: "100%"}} show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Apply as teacher</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{padding: "1rem", overflow: "auto"}}>
        <form onSubmit={applyAsTeacher}>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="first_name" className="form-label">First Name</label>
                        <input type="text" className="form-control" id="first_name" name='first_name' value={formData.first_name} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="last_name" className="form-label">Last Name</label>
                        <input type="text" className="form-control" id="last_name" name='last_name' value={formData.last_name} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="address" className="form-label">Address</label>
                <textarea type="text" rows={4} className="form-control" id="address" name='address' value={formData.address} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="phone" className="form-label">Phone</label>
                        <input type="number" className="form-control" id="phone" name='phone' value={formData.phone} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="email_id" className="form-label">Email</label>
                        <input type="email" className="form-control" id="email_id" name='email_id' value={formData.email_id} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    </div>
                </div>
            </div>

            <div className="mb-3">
                <label className="form-label">Gender</label><br/>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" id="male" value="male" onChange={(e) => {
                        if(e.target.checked){
                            handleFormDataChange("gender", "male")
                        }
                    }}/>
                    <label className="form-check-label" for="male">Male</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" id="female" value="female"  onChange={(e) => {
                        if(e.target.checked){
                            handleFormDataChange("gender", "female")
                        }
                    }}/>
                    <label className="form-check-label" for="female">Female</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="gender" id="others" value="others"  onChange={(e) => {
                        if(e.target.checked){
                            handleFormDataChange("gender", "others")
                        }
                    }}/>
                    <label className="form-check-label" for="others">Others</label>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="CV" className="form-label">CV</label>
                        <input type="file" className="form-control" id="CV" onChange={setFilesData}/>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                    <div className="mb-3">
                        <label htmlFor="teaching_experience" className="form-label">Teaching Experience (In years)</label>
                        <input type="number" className="form-control" id="teaching_experience" name='teaching_experience' value={formData.teaching_experience} onChange={(e) => handleFormDataChange(e.target.name, e.target.value)}/>
                    </div>
                </div>
            </div>

            <div className='d-flex justify-content-end'>
                <button type="submit" className="btn btn-primary" disabled={apiCalled}>{apiCalled ? "Submitting..." : "Submit"}</button>
            </div>
        </form>

            
        </Modal.Body>
    </Modal>
  )
}

export default BecomeATeacherModal