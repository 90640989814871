import React from 'react'

function EBookComp() {
  return (
    <div className="page-wrapper">
        <div className="page-content">
          <div className="container">
            <div className="cont mb-3 mt-3">
              <h1>Subject</h1>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="nav nav-pills nav-fill mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item item-hover math-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-mathsebooks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-mathsebooks"
                      type="button"
                      role="tab"
                      aria-controls="pills-mathsebooks"
                      aria-selected="true"
                    >
                      Maths
                    </button>
                  </li>
                  <li className="nav-item item-hover bio-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-bioebooks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-bioebooks"
                      type="button"
                      role="tab"
                      aria-controls="pills-bioebooks"
                      aria-selected="false"
                    >
                      Bio
                    </button>
                  </li>
                  <li className="nav-item item-hover phy-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-phyebooks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-phyebooks"
                      type="button"
                      role="tab"
                      aria-controls="pills-phyebooks"
                      aria-selected="false"
                    >
                      Physics
                    </button>
                  </li>
                  <li className="nav-item item-hover eng-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-engebooks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-engebooks"
                      type="button"
                      role="tab"
                      aria-controls="pills-engebooks"
                      aria-selected="false"
                    >
                      English
                    </button>
                  </li>
                  <li className="nav-item item-hover che-tb" role="presentation">
                    <button
                      className="nav-link nv-link"
                      id="pills-cheebooks-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-cheebooks"
                      type="button"
                      role="tab"
                      aria-controls="pills-cheebooks"
                      aria-selected="false"
                    >
                      Chemistry
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-mathsebooks"
                    role="tabpanel"
                    aria-labelledby="pills-mathsebooks-tab"
                    tabIndex="0"
                  >
                    <div className="row row-two">
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (1).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart20"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (3).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart21"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (2).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart22"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="main-div">
                          <div>
                            <img
                              src="./assets/images/Frame (4).png"
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                          <h2>Chapter</h2>
                          <div id="piechart23"></div>
                          <div className="main-div-two">
                            <h4>Total Topic : <span>12</span></h4>
                            <h5>Duration : <span>2 hour 10 mint</span></h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-bioebooks"
                    role="tabpanel"
                    aria-labelledby="pills-bioebooks-tab"
                    tabIndex="0"
                  >
                    Bio
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-phyebooks"
                    role="tabpanel"
                    aria-labelledby="pills-phyebooks-tab"
                    tabIndex="0"
                  >
                    Physics
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-engebooks"
                    role="tabpanel"
                    aria-labelledby="pills-engebooks-tab"
                    tabIndex="0"
                  >
                    English
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-cheebooks"
                    role="tabpanel"
                    aria-labelledby="pills-cheebooks-tab"
                    tabIndex="0"
                  >
                    Chemistry
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row row-two">
              <div className="col-md-12">
                <div className="note-flex">
                  <h4>Notes</h4>
                  <div>
                    <label className="filelabel">
                      <img
                        src="assets/images/uploadicon.png"
                        className="img-fluid"
                        alt=""
                      />
                      <input className="FileUpload1" id="" name="" type="file" />
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <p className="uploadsec">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Eligendi quae accusamus quisquam qui ratione, iusto totam
                    magnam veniam molestiae ad recusandae illo, vero minima est
                    quos fuga architecto, ullam cumque. Cumque, eveniet fugiat?
                    Quaerat possimus ea ratione officia alias. Consequatur
                    reiciendis ut odio totam hic, nostrum, quia quis in harum
                    unde assumenda labore sit placeat a odit minus ea et! Quia
                    expedita aut illo libero maiores eaque accusamus
                    consequuntur in culpa itaque ullam numquam, excepturi fuga
                    inventore facere dolorum dignissimos fugiat, perferendis
                    dolores perspiciatis minima nulla temporibus! Quod, neque
                    tempore est commodi beatae eligendi quis ipsam illum
                    cupiditate, eum deserunt.
                  </p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
  )
}

export default EBookComp